import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NbPopoverDirective } from '@nebular/theme';
import mapboxgl from 'mapbox-gl';
import { MapService } from '../../@core/services/map.service';
import { DispatchHubService } from '../../@core/services/dispatchhub.service';
//import { TemperatureComponent } from "../dashboard/temperature/temperature.component";
import { DispatchHubReplayComponent } from './options/replay/replay.component';
import { GPSLoggingService } from '../../@core/services/gpsLogging.service';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { TeamService } from '../../@core/services/team.service';
import { HttpClient } from '@angular/common/http';
import { SubSink } from 'subsink';
import { TimeManagmentService } from '../timemanagement/services/timemanagment.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { WorkOrderService } from '../../@core/services/workorder.service';
import { ColorPallet, DefaultColors } from '../../@core/data/colors';
import { on } from 'events';
import { DatePipe } from '@angular/common';
const MapboxTraffic = require('@mapbox/mapbox-gl-traffic');
const polylineDecode = require('geojson-polyline').decode;

export interface IMarker {
  id: string;
  type: 'point' | 'group';
  markers?: IMarker[];
  point: [number, number];
  color?: string;
  text?: string;
}

@Component({
  selector: 'next-dispatchhub',
  templateUrl: './dispatchhub.component.html',
  styleUrls: ['./dispatchhub.component.scss'],
})
export class DispatchHubComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  replayComponent: DispatchHubReplayComponent;
  gridSegments: any;
  schedulerSegments: any;
  schedulerWorkOrders: any;
  markerBuffer: IMarker[];
  mapContainerID = new Date().getTime().toString();

  optionShowLastActivity = true;
  optionShowGrid = true;
  optionShowScheduledRoute = false;
  optionShowWorkerHomeBase = true;

  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 32.7767;
  lng = -96.797;
  zoom = 12;
  logs: any;
  replayDate: Date;
  replayInterval: any;
  routerMarkers: any = [];
  gridWorkOrders: any[] = [];
  location: any;
  selectedTeams: any;
  mapLayers: any[] = [];
  mapSources: any[] = [];
  baseUrl: string = 'https://api.mapbox.com';
  apiKey: string = `access_token=${environment.mapbox.publicToken}`;

  private teamsLastTimeSheets: any = [];
  private workOrderMarkers: mapboxgl.Marker[] = [];
  private teamMarkers: mapboxgl.Marker[] = [];

  @ViewChild(ElementRef) editTmpl: any;

  @ViewChild(NbPopoverDirective) mapOptionPopup: NbPopoverDirective;
  @Input() isDispatchHub: boolean = true;
  @Input() hideDropDowns: boolean = false;
  @Input() fullScreen: boolean = true;
  @Input() height: number;
  @Input() set serviceVisit(serviceVisit: any) {
    this._serviceVisit = serviceVisit;
    if (!serviceVisit) return;
    this.drawServiceVisit();
  }
  get serviceVisit() {
    return this._serviceVisit;
  }
  private _serviceVisit: any;

  constructor(
    private dispatchHubService: DispatchHubService,
    private gpsLogs: GPSLoggingService,
    private teamService: TeamService,
    private http: HttpClient,
    private tmService: TimeManagmentService,
    @Inject(WorkOrderService) private workOrderService: WorkOrderService,
    private readonly el: ElementRef<HTMLElement>,
  ) {
    (mapboxgl as any).accessToken = environment.mapbox.publicToken;
    if (!this.height) {
      this.height = window.innerHeight - 150;
    }
  }

  ngOnInit(): void {
    // this.initGridSegmentListener();
    // this.initSchedulerSegmentListener();
  }

  ngAfterViewInit() {
    //this.drawLayers();
    this.buildMap();
  }

  ngOnDestroy(): void {
    this.map.remove();
    this.subs.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.map?.resize();
  }

  initSubscriptions() {
    if (!this.isDispatchHub) return;
    this.subs.sink = this.workOrderService
      .getGridRecordsObservable()
      .subscribe((workOrders: any) => {
        this.gridWorkOrders = workOrders;
        this.drawWorkOrders();
      });

    this.subs.sink = this.tmService
      .getMyTeamsLastTimeSheet()
      .pipe(distinctUntilChanged())
      .subscribe((timeSheets: any) => {
        this.teamsLastTimeSheets = timeSheets;
        if (!timeSheets) return;
        // this.refreshAllLayers();
        this.drawTeamMembers();
      });

    this.subs.sink = this.workOrderService
      .getSchedulerRecordsObservable()
      .pipe(distinctUntilChanged())
      .subscribe((workOrders: any) => {
        this.schedulerWorkOrders = workOrders;
        if (!workOrders) return;
        this.refreshAllLayers();
      });

    this.subs.sink = this.workOrderService
      .getSelectedWorkOrderObservable()
      .subscribe((workOrder: any) => {
        if (!workOrder || !this.map) return;
        this.map.flyTo({
          center: [workOrder.lng, workOrder.lat],
          zoom: 11,

          speed: 3,
          essential: true,
        });
      });
  }

  drawServiceVisit() {
    if (!this.map) return;

    this.removeAllMapLayers();
    if (this.routerMarkers) {
      this.routerMarkers.forEach((marker) => {
        marker.remove();
      });
    }

    this.teamsLastTimeSheets = [];

    const serviceVisit = this.serviceVisit;

    if (!serviceVisit?.lat || !serviceVisit?.lng) {
      return;
    }

    const markerColor = serviceVisit?.statusRef?.color || '#3366ff';
    const el = document.createElement('div');
    el.className = 'marker';
    el.style.setProperty('--marker-color', `${markerColor}`);
    el.style.setProperty('filter', 'brightness(1.2)');
    el.innerHTML = `<span> <i class="fa-solid fa-shop rotateIcon"></i></span>`;

    const marker = new mapboxgl.Marker(el)
      .setLngLat([serviceVisit?.lng, serviceVisit?.lat])
      .addTo(this.map);

    this.routerMarkers.push(marker);

    this.teamsLastTimeSheets = [];
    if (serviceVisit?.isActive) {
      const lastTimeSheetMap = new Map();

      serviceVisit?.timeSheets?.forEach((timeSheet) => {
        if (!lastTimeSheetMap.has(timeSheet?.worker?.id)) {
          lastTimeSheetMap.set(timeSheet?.worker?.id, timeSheet);
        } else {
          const lastTimeSheet = lastTimeSheetMap.get(timeSheet?.worker?.id);
          if (
            new Date(timeSheet?.startDateTime) >
            new Date(lastTimeSheet?.startDateTime)
          ) {
            lastTimeSheetMap.set(timeSheet?.worker?.id, timeSheet);
          }
        }
      });

      this.teamsLastTimeSheets = Array.from(lastTimeSheetMap.values());
      this.optionShowLastActivity = true;
    }
    this.drawTeamMembers();

    serviceVisit?.serviceVisitWorkers?.forEach((svw) => {
      //does this.teamsLastTimeSheets have this worker and it is open?

      if (['Planned', 'Scheduled'].indexOf(svw?.status) === -1) {
        return;
      }

      var el = document.createElement('div');
      el.className = 'marker';
      el.style.setProperty('--marker-color', `#878787`);
      el.innerHTML = `
          <span><i class="fa-solid fa-car rotateIcon"></i></span>
          `;

      if (svw?.estTravelFromLatLng) {
        const marker = new mapboxgl.Marker(el)
          .setLngLat([
            svw?.estTravelFromLatLng?.split(',')[1],
            svw?.estTravelFromLatLng?.split(',')[0],
          ])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                `<img style="width:50px;height:50px;" src="${svw?.worker?.user?.avatarURL}" />
              <h5> ${svw?.worker?.displayName} </h5>`,
              ),
          )
          .addTo(this.map);

        this.routerMarkers.push(marker);
      }

      if (svw?.estTravelPolyline) {
        const polygon = {
          type: 'Polygon',
          coordinates: [svw.estTravelPolyline],
        };
        const directions = polylineDecode(polygon);

        if (!directions?.coordinates) return;

        let routeColor = '#3887be';
        // if (+svw.estTravelTime > 30) {
        //   routeColor = 'red';
        // }

        this.drawMapRoute(directions.coordinates[0], routeColor);
      }
    });

    if (this.routerMarkers.length > 1 || this.teamMarkers.length > 0) {
      var bounds = new mapboxgl.LngLatBounds();
      this.routerMarkers?.forEach((marker) => {
        bounds.extend(marker.getLngLat());
      });

      this.teamMarkers?.forEach((marker) => {
        bounds.extend(marker.getLngLat());
      });

      this.map?.fitBounds(bounds, { padding: 100, minZoom: 10, speed: 1.5 });
    } else {
      this.map?.setCenter([serviceVisit?.lng, serviceVisit?.lat]);
      this.map?.setZoom(10);
    }
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      container: this.mapContainerID,
      style: this.style,
      zoom: 10,
      center: [
        this.serviceVisit?.lng || this.lng,
        this.serviceVisit?.lat || this.lat,
      ],
      projection: { name: 'globe' },
    });

    this.map.on('load', (event) => {
      this.map.resize();
      this.map.setFog({});
      if (this.isDispatchHub) {
        this.map.addControl(new MapboxTraffic());
        // this.drawMapLayers();
        this.initSubscriptions();
      } else {
        this.drawServiceVisit();
        return;
      }
      setTimeout(() => {
        this.map.resize();
      }, 500);
    });
  }

  setupMapListeners() {
    const map = this.map;
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    map.on('mouseenter', 'source-1', (e) => {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = 'pointer';

      // Copy coordinates array.
      const coordinates = (<any>(
        e
      ))?.features?.[0]?.geometry?.coordinates?.slice();
      const description = e?.features?.[0]?.properties?.description;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(coordinates).setHTML(description).addTo(map);
    });

    map.on('mouseleave', 'places', () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    });
  }

  async drawMapLayers() {
    if (!this.map) return;
    if (!this.isDispatchHub) return;

    this.drawTeamMembers();
    this.fitBoundsAllMarkers();
  }

  refreshAllLayers() {
    this.drawWorkOrders();
    this.drawTeamMembers();
    this.showSchedulerRoutes();
  }

  async drawTeamMembers() {
    this.teamMarkers?.forEach((marker) => {
      marker.remove();
    });
    this.teamMarkers = [];

    if (!this.optionShowLastActivity) return;
    if (!this.teamsLastTimeSheets?.length || !this.map) return;

    this.teamsLastTimeSheets?.forEach(async (timeSheet: any) => {
      if (!timeSheet?.lat || !timeSheet?.lng) {
        return;
      }
      var el = document.createElement('div');
      el.className = 'marker';

      el.style.setProperty(
        '--marker-color',
        `${timeSheet?.activityCode?.fabColor}`,
      );
      if (timeSheet?.isOpen) {
        el.style.setProperty('--marker-animation', 'pulse4');
      } else {
        el.style.setProperty('opacity', '0.8');
        el.style.setProperty('--marker-color', `var(--ion-color-medium`);
      }
      el.innerHTML = `<div class="userLocation avatar ${
        timeSheet?.isOpen ? 'online' : ''
      }"><i class="rotateIcon b-fa b-fa-${
        timeSheet?.activityCode?.fabIcon
      }"></i></div>`;

      const marker = new mapboxgl.Marker(el)
        .setLngLat([timeSheet?.lng, timeSheet?.lat])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<img src="${timeSheet?.worker?.user?.avatarURL}" />
              <h5> ${timeSheet?.worker?.displayName} </h5>
              <p> ${timeSheet?.activityCode?.code}  </p>`,
            ),
        )
        .addTo(this.map);
      this.teamMarkers.push(marker);

      marker.getElement().addEventListener('click', () => {
        // this.event.emit({
        //   event: "teammember_click",
        //   data: timeSheet,
        // });
      });
    });
  }

  drawWorkOrders() {
    const workOrders = this.gridWorkOrders;
    if (!workOrders || !this.map) {
      return;
    }
    //this.removeAllMapLayers();
    this.workOrderMarkers.forEach((marker) => {
      marker.remove();
    });
    if (!this.optionShowGrid) return;

    const sortedWorkOrders = workOrders;
    let array = [];
    for (let i = sortedWorkOrders.length - 1; i >= 0; i--) {
      let workOrder = sortedWorkOrders[i];
      if (!workOrder?.lat || !workOrder?.lng) {
        continue;
      }
      null;
      array.push({
        lat: workOrder?.lng,
        lng: workOrder?.lat,
      });
      let animation = '';
      let color: string = '#6d6d6d';
      //if (i === 0) {
      if (sortedWorkOrders[i]?.selected) {
        color = '#78b66f';
        animation = 'pulse4';
      }

      var el = document.createElement('div');
      el.className = 'marker';
      el.style.setProperty('--marker-color', color);
      //  el.style.setProperty('--marker-animation', animation);
      const suffix = workOrder?.type?.slice(0, 3);
      el.innerHTML = `<span><b class="txt"> ${suffix}</b></span>`;

      const marker = new mapboxgl.Marker(el)
        .setLngLat([workOrder?.lng, workOrder?.lat])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h5> ${workOrder?.workOrderNum} </h5><p> ${workOrder?.shipTo?.name}  </p> <button class="btn btn-primary" (click)="openWorkOrder(${workOrder?.id})">Open</button>`,
            ),
        )
        .addTo(this.map);
      this.workOrderMarkers.push(marker);
      marker.getElement().addEventListener('click', () => {
        // this.event.emit({
        //   event: "marker_click",
        //   data: workOrder.workOrderNum,
        // });
      });
    }
  }

  onLocationChange(location: any) {
    this.location = location;
    this.onTeamChange(location.teams);
  }

  onTeamChange($event) {
    this.selectedTeams = $event;
    this.getTeamWorkers();
  }

  getTeamWorkers() {
    let ids = '';
    this.selectedTeams.forEach(({ id }) => {
      if (ids === '') ids = id;
      else ids += `,${id}`;
    });
    this.subs.sink = this.teamService
      .getTeamsByIds(ids, this.location?.id)
      .subscribe(({ data }) => {});
  }

  removeAllMapLayers() {
    this.mapLayers.forEach((layer) => {
      this.map.removeLayer(layer);
    });
    this.mapSources.forEach((source) => {
      this.map.removeSource(source);
    });
    this.mapLayers = [];
    this.mapSources = [];
  }

  showSchedulerRoutes() {
    this.removeAllMapLayers();
    this.routerMarkers.forEach((marker) => {
      marker.remove();
    });

    const sortedWorkOrders = this.sortWorkOrdersByWorkerAndDate(
      this.schedulerWorkOrders,
    );
    let workerCnt = 0;

    Object.keys(sortedWorkOrders).forEach(async (worker: any) => {
      workerCnt++;
      const color = ColorPallet[workerCnt][1];
      let array = [];
      let cnt = 0;
      sortedWorkOrders[worker].workOrders.forEach((workOrder) => {
        cnt++;
        array.push([workOrder.lng, workOrder.lat]);

        if (cnt === 1 && workOrder?.estTravelFromLatLng) {
          var el = document.createElement('div');
          el.className = 'marker';
          el.style.setProperty('--marker-color', `Green`);
          el.innerHTML = `
          <img style="margin-top:-70px; margin-right:15px;" src="${workOrder?.serviceVisitWorkers[0]?.worker?.user?.avatarURL}" width="40" height="40" class="rounded-circle" />
          <span ><b class="txt"> Start </b></span>
          `;

          const marker = new mapboxgl.Marker(el)

            .setLngLat([
              workOrder?.estTravelFromLatLng?.split(',')[1],
              workOrder?.estTravelFromLatLng?.split(',')[0],
            ])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  `<h5> Home Base </h5>
                  ${workOrder?.serviceVisitWorkers
                    ?.map(
                      (worker) =>
                        `
                        <p class="mb-1">
                        <img src="${worker?.worker?.user?.avatarURL}" width="40" height="40" class="rounded-circle" />
                         ${worker?.worker?.displayName} </p>`,
                    )
                    ?.join('')}
                 `,
                ),
            )
            .addTo(this.map);

          this.routerMarkers.push(marker);
        }

        var el = document.createElement('div');
        el.className = 'marker';
        el.style.setProperty(
          '--marker-color',
          `${workOrder?.statusRef?.color || color}`,
        );
        const label =
          sortedWorkOrders[worker].workOrders.length === cnt ? 'End' : cnt;
        el.innerHTML = ` <img style="margin-top:-70px; margin-right:15px;" src="${workOrder?.serviceVisitWorkers[0]?.worker?.user?.avatarURL}" width="40" height="40" class="rounded-circle" />
        <span ><b class="txt"> ${label}</b></span>`;

        const marker = new mapboxgl.Marker(el)

          .setLngLat([workOrder?.lng, workOrder?.lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                `<h5> ${workOrder?.serviceVisitNum} </h5>
                <p> ${workOrder?.customer?.name} </p> 
                ${workOrder?.serviceVisitWorkers
                  ?.map(
                    (worker) =>
                      `
                      <p class="mb-1">
                      <img src="${worker?.worker?.user?.avatarURL}" width="30" height="30" class="rounded-circle" />
                       ${worker?.worker?.displayName} </p>`,
                  )
                  ?.join('')}
                <p> ${new Date(
                  workOrder?.scheduledStartDateTime,
                ).toLocaleString()}</p>
                <button class="btn btn-primary" (click)="openWorkOrder(${workOrder?.id})">Open</button>`,
              ),
          )
          .addTo(this.map);

        this.routerMarkers.push(marker);

        if (workOrder?.estTravelPolyline) {
          const polygon = {
            type: 'Polygon',
            coordinates: [workOrder.estTravelPolyline],
          };
          const directions = polylineDecode(polygon);

          if (!directions?.coordinates) return;

          let routeColor = '#3887be';
          if (+workOrder.estTravelTime > 30) {
            routeColor = 'red';
          }

          this.drawMapRoute(directions.coordinates[0], routeColor);
        }
      });
    });
  }

  sortWorkOrdersByWorkerAndDate(_workOrders) {
    if (!_workOrders) return;
    let sortedObject = {};
    const workOrders = [..._workOrders];
    workOrders.sort((a, b) => {
      return (
        <any>new Date(a.scheduledStartDateTime) -
        <any>new Date(b.scheduledStartDateTime)
      );
    });

    workOrders.forEach((_workOrder) => {
      const workerId = _workOrder?.serviceVisitWorkers[0]?.worker?.id;
      if (!sortedObject[workerId]) {
        sortedObject[workerId] = {};
        sortedObject[workerId].workOrders = [];
      }
      sortedObject[workerId].workOrders.push(_workOrder);
    });

    return sortedObject;
  }

  showReplay() {
    this.subs.sink = this.getGPSLogHistory().subscribe(({ data }) => {
      const logs = (<any>data).getGPSLogs;
      this.logs = logs;
      this.drawGPSHistoryOnMap(logs);
    });

    //this.drawTruckOnMap(this.logs[cnt]);
  }

  fitBoundsAllMarkers() {
    if (!this.teamMarkers || !this.map) return;
    var bounds = new mapboxgl.LngLatBounds();

    this.teamMarkers?.forEach((marker) => {
      bounds.extend(marker.getLngLat());
    });

    this.map?.fitBounds(bounds, { zoom: 11 });
  }

  getGPSLogHistory() {
    const startDate: Date = moment(this.replayDate)
        .startOf('day')
        .utc()
        .toDate(),
      endDate: Date = moment(this.replayDate).endOf('day').utc().toDate(),
      workerIds: string[] = [];

    return this.gpsLogs.getGPSLogs(startDate, endDate, workerIds);
  }

  drawGPSHistoryOnMap(logs) {
    let array = [];
    logs.forEach((log) => {
      array.push([log.longitude, log.latitude]);
    });
    this.drawMapRoute(array);
    this.drawTruckOnMap(array[0]);

    this.map.flyTo({
      center: array[0],
      speed: 0.5,
    });
    this.drawTruckReplay(array);
  }

  drawTruckReplay(locations) {
    let cnt = 0;
    this.map.setPitch(30);
    this.map.jumpTo({ center: locations[0], zoom: 14 });
    clearInterval(this.replayInterval);
    this.replayInterval = setInterval(() => {
      const location = locations[cnt];
      this.drawTruckOnMap(location);
      this.map.panTo(location);
      cnt++;
      if (cnt === locations.length) {
        clearInterval(this.replayInterval);
      }
    }, 200);
  }

  drawTruckOnMap(location) {
    const routeSource: any = this.map.getSource('truck');
    if (routeSource) {
      routeSource.setData({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: location,
        },
      });
      return;
    }

    this.map.addSource('truck', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: location,
        },
      },
    });
    this.map.addLayer({
      id: 'truck-circle',
      type: 'circle',
      source: 'truck',
      paint: {
        'circle-radius': 20,
        'circle-color': 'lightgreen',
        'circle-stroke-color': '#3887be',
        'circle-stroke-width': 3,
      },
    });
  }

  drawMapRoute(locations, color = '#3887be') {
    const lIndex1 = `layer-${this.mapLayers.length + 1}`;
    const sIndex = `source-${this.mapSources.length + 1}`;
    this.mapSources.push(sIndex);
    this.mapLayers.push(lIndex1);

    this.map.addSource(`${sIndex}`, {
      type: 'geojson',

      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: locations,
        },
      },
    });

    this.map.addLayer(
      {
        id: `${lIndex1}`,
        type: 'line',
        source: `${sIndex}`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': color,
          'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 12],
        },
      },
      'waterway-label',
    );

    const lIndex2 = `layer-${this.mapLayers.length + 1}`;
    this.mapLayers.push(lIndex2);

    this.map.addLayer(
      {
        id: `${lIndex2}`,
        type: 'symbol',
        source: `${sIndex}`,
        layout: {
          'symbol-placement': 'line',
          'text-field': '▶',
          'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
          'symbol-spacing': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12,
            30,
            22,
            160,
          ],
          'text-keep-upright': false,
        },
        paint: {
          'text-color': '#3887be',
          'text-halo-color': 'hsl(55, 11%, 96%)',
          'text-halo-width': 3,
        },
      },
      'waterway-label',
    );
  }

  drawMarker(lng, lat, color: string = '#3bb2d0', txt = 'abc') {
    var el = document.createElement('div');
    el.className = 'marker';
    el.style.setProperty('--marker-color', `${color}`);
    //  el.style.setProperty('--marker-animation', animation);

    el.innerHTML = `<span ><b class="txt"> ${txt}</b></span>`;
    return new mapboxgl.Marker(el)
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML('<h3>' + 'Worker' + '</h3><p>' + 'START' + '</p>'),
      )
      .setLngLat([lng, lat])
      .addTo(this.map);
  }

  async getMapBoxDirections(
    start: any,
    end: any,
    geoType: string = 'polyline',
  ) {
    if (!start || !end) return;
    let pointA: string = `${start[0]},${start[1]}`;
    let pointB: string = `${end[0]},${end[1]}`;
    const uri = encodeURI(
      `${this.baseUrl}/directions/v5/mapbox/driving/${pointA};${pointB}?radiuses=1000;100&geometries=${geoType}&${this.apiKey}`,
    );
    var resp: any = await this.http.get(uri).toPromise();
    if (!resp.routes || !resp.routes[0]) return;
    let dir: any = {
      distance: resp?.routes[0]?.distance,
      duration: resp?.routes[0]?.duration,
      geometry: resp?.routes[0]?.geometry,
    };
    return dir;
  }

  showOptionPopup() {
    this.mapOptionPopup.show();
  }

  closeOptionPopup() {
    this.mapOptionPopup.hide();
  }

  resizeMap() {
    this.map.resize();
  }

  drawLayers() {
    //this.drawSchedulerMapMarkers();
    //this.fitBoundsAllMarkers();
  }

  drawSchedulerMapMarkers() {
    // var bounds = new mapboxgl.LngLatBounds();
    // this.schedulerSegments.forEach((segment) => {
    //   this.drawMarker(
    //     segment.workOrder.shipTo.lng,
    //     segment.workOrder.shipTo.lat
    //   );
    //   bounds.extend([
    //     segment.workOrder.shipTo.lng,
    //     segment.workOrder.shipTo.lat,
    //   ]);
    // });
    // this.map.fitBounds(bounds);
  }

  mapOptionChange(option, value) {
    this[option] = value;
    this.refreshAllLayers();
  }
}
