import { gql } from 'apollo-angular';

export const Fragment_WorkOrder = gql`
  fragment WorkOrderFragment on WorkOrder {
    id
    type
    category
    status
    createdAt
    updatedAt
    workOrderNum
    serial
    make
    poNumber
    priority
    customerEquipmentNum
    model
    address
    address1
    address2
    city
    state
    zipcode
    country
    serviceZone
    lat
    lng
    subject
    scheduledStartDateTime
    scheduledEndDateTime
    isPastDue
    isMissed
    isClosed
    contactEmail
    contactName
    contactPhone
    rescheduleCount
    pastDueCount
    missedCount
    mapURL
    estTravelTime
    estTravelDistance
    estTravelHash
    estTravelFromLatLng
    branchNum
    locationNum
    departmentNum
    workerNames
    projectNum
    taskNum
    lastLabor
    openedForReason
    estTechReturnDate
    preferredResourceRef {
      id
      teamNum
      workerNum
      displayName
    }
    WorkOrderSegments {
      id
      SegmentReportingCode {
        id
        reportingCode {
          id
          name
        }
      }
    }
    workOrderWorkers {
      id
      deleted
      worker {
        id
        workerNum
        team
        displayName
        initials
        user {
          id
          avatarURL
        }
      }
    }
    shipTo {
      id
      name
      customerNum
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
    }
    billTo {
      id
      name
      customerNum
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
    }
    equipment {
      id
      equipmentNum
      make
      model
      fleetType
      fleetCode
      station
      category
    }
  }
`;

export const QUERY_GETWORKORDERS = gql`
  query ($search: DtoPaginatedFilter!) {
    getWorkOrders(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          ...WorkOrderFragment
          location {
            id
            locationNum
          }
        }
      }
    }
  }
  ${Fragment_WorkOrder}
`;
