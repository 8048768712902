import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { CustomerService } from '../../services/customer.service';
import { ContactListComponent } from '../contact-list/contact-list.component';

@Component({
  selector: 'customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
})
export class CustomerDetailComponent implements OnInit {
  form = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(''),
    type: new FormControl(''),
    externalId: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zipcode: new FormControl(''),
    country: new FormControl(''),
    lat: new FormControl(''),
    lng: new FormControl(''),
    customerNum: new FormControl(''),
    billToId: new FormControl(''),
    instructions: new FormControl(''),
  });
  customerTypes: any = [];
  customer;
  _customerId;
  billTo;
  activeTab = 'Details';
  windowRef;

  // Tab visibility flags
  showWorkOrders = false;
  showEquipment = false;
  showContacts = false;
  showAttachments = false;
  showServiceVisits = false;
  @Input() set customerId(val: any) {
    this._customerId = val;
    this.getCustomer(val);
  }

  get customerId() {
    return this.customerId;
  }

  constructor(
    private readonly customerService: CustomerService,
    private readonly ngZone: NgZone,
    private readonly toaster: NbToastrService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.getCustomerTypes();
  }

  onTabChange(tab: any) {
    this.activeTab = tab.tabTitle;

    // Set visibility flag for the selected tab to true if not already set
    switch (tab.tabTitle) {
      case 'Work Orders':
        if (!this.showWorkOrders) this.showWorkOrders = true;
        break;
      case 'Equipment':
        if (!this.showEquipment) this.showEquipment = true;
        break;
      case 'Contacts':
        if (!this.showContacts) this.showContacts = true;
        break;
      case 'Attachments':
        if (!this.showAttachments) this.showAttachments = true;
        break;
      case 'Service Visits':
        if (!this.showServiceVisits) this.showServiceVisits = true;
        break;
    }
  }

  gotoWorkOrder(workOrder) {
    this.router.navigate(['/pages/workorders', workOrder.id]);
  }

  getCustomer(id) {
    this.customerService.getCustomer(id).subscribe(({ data }) => {
      const cust: any = data;
      this.customer = cust.getCustomer;
      if (!this.customer) return;
      this.billTo = this.customer.billTo;
      this.form.reset();
      if (this.windowRef) {
        this.windowRef.config.title = `${this.customer?.customerNum} - ${this.customer?.name}`;
      }
      this.ngZone.run(() => {
        this.form.patchValue(this.customer);
      });
    });
  }

  updateCustomer() {
    const update = this.form.value;
    update.customerNum = undefined;
    update.billToId = this.billTo?.id || null;

    this.customerService.updateCustomer(update).subscribe(({ data }) => {
      this.toaster.primary('Success', 'Customer Updated');
    });
  }

  getCustomerTypes() {
    this.customerService.getCustomerTypes().subscribe(({ data }) => {
      const rtn: any = data;
      this.customerTypes = rtn.__type.enumValues;
    });
  }

  billToChange(customer: any) {
    this.billTo = customer;
  }
}
