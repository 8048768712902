import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { QueueApiService } from './queue-api.service';
import { DtoAddQueue } from '../dto/dtoAddQueue.dto';
import { map } from 'rxjs/operators';
import { DtoGetQueuesByObject } from '../dto/DtoGetQueuesByObject';
import { DtoUpdateQueue } from '../dto/dtoUpdateQueue';
import { Query_GetBlockTimes } from 'packages/stack-web/src/app/@core/graphql/queries/blocktime/getBlockTimes.query';
import { QUERY_GETCHECKLISTS } from 'packages/stack-web/src/app/@core/graphql/queries/checklist/getChecklists.query';
import { Query_GetContacts } from 'packages/stack-web/src/app/@core/graphql/queries/customers/getContacts.query';
import { QUERY_GETCUSTOMERS } from 'packages/stack-web/src/app/@core/graphql/queries/customers/getcustomers.query';
import { QUERY_GetEquipment } from 'packages/stack-web/src/app/@core/graphql/queries/equipment/getequipment.query';
import { QUERY_GetEquipmentInventory } from 'packages/stack-web/src/app/@core/graphql/queries/equipment/getEquipmentInventory.query';
import { Query_GetEquipmentModels } from 'packages/stack-web/src/app/@core/graphql/queries/equipment/getEquipmentModels.query';
import { Query_GetIntegrationLogs } from 'packages/stack-web/src/app/@core/graphql/queries/logs/getIntegrationLogs.query';
import { Query_GetMobileChangeLogs } from 'packages/stack-web/src/app/@core/graphql/queries/logs/getMobileChangeLogs.query';
import { QUERY_GETOBJECTS } from 'packages/stack-web/src/app/@core/graphql/queries/objectmetadata/getObjects';
import { Query_GetOrderLines } from 'packages/stack-web/src/app/@core/graphql/queries/order/getOrderLines.query';
import { Query_GetOrders } from 'packages/stack-web/src/app/@core/graphql/queries/order/getOrders.query';
import { Query_GetQueues } from 'packages/stack-web/src/app/@core/graphql/queries/queues/getQueues';
import { Query_GetServiceVisits } from 'packages/stack-web/src/app/@core/graphql/queries/service-visit/getServiceVisits.query';
import { Query_GetServiceVisitWorkers } from 'packages/stack-web/src/app/@core/graphql/queries/service-visit/getServiceVisitWorkers.query';
import { Query_GetServiceVisitWorkOrder } from 'packages/stack-web/src/app/@core/graphql/queries/service-visit/getServiceVisitWorkOrder.query';
import { QUERY_GetActivityCodesPaginated } from 'packages/stack-web/src/app/@core/graphql/queries/timemanagment/getActivityCodesPaginated';
import { QUERY_GetTimeSheets } from 'packages/stack-web/src/app/@core/graphql/queries/timemanagment/getTimeSheets.query';
import { Query_GetUserRolesPaginated } from 'packages/stack-web/src/app/@core/graphql/queries/users/getUserRolesPaginated.query';
import { QUERY_GETUSERS } from 'packages/stack-web/src/app/@core/graphql/queries/users/getusers.query';
import { Query_GetWorkers } from 'packages/stack-web/src/app/@core/graphql/queries/workers/getworkers.query';
import { QUERY_GETWORKORDERS } from 'packages/stack-web/src/app/@core/graphql/queries/workorders/getWorkOrders.query';
import { Query_GetWorkOrderWorkers } from 'packages/stack-web/src/app/@core/graphql/queries/workorders/getWorkOrderWorkers.query';
import { Query_GetWorkOrderSegmentLines } from 'packages/stack-web/src/app/@core/graphql/queries/workordersegments/getWorkOrderSegmentLines.query';
import { QUERY_GETWORKORDERSEGMENTS } from 'packages/stack-web/src/app/@core/graphql/queries/workordersegments/getWorkOrderSegments';
import { QUERY_GetEquipmentTransfers } from 'packages/stack-web/src/app/@core/graphql/queries/equipment/getequipmenttransfer.query';
import { QUERY_GetEquipmentTransferLines } from 'packages/stack-web/src/app/@core/graphql/queries/equipment/getequipmenttransferlines.query';
import { QUERY_GETCHECKLISTTEMPLATES } from 'packages/stack-web/src/app/@core/graphql/queries/checklist/getChecklistTemplates.query';
import { QUERY_GetEquipmentEMR } from 'packages/stack-web/src/app/@core/graphql/queries/equipment/getequipmentemr.query';
import { QUERY_GetEquipmentEMRLines } from 'packages/stack-web/src/app/@core/graphql/queries/equipment/getequipmentemrlines.query';
import { Query_GetStations } from 'packages/stack-web/src/app/@core/graphql/queries/locations/getStations.query';
import { Query_GetLocationsPaginated } from 'packages/stack-web/src/app/@core/graphql/queries/locations/getLocationsPaginated.query';
import { Query_GetBranchesPaginated } from 'packages/stack-web/src/app/@core/graphql/queries/locations/getBranchesPaginated.query';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { Query_GetQueueViewGroups } from 'packages/stack-web/src/app/@core/graphql/queries/queues/getQueueViewGroups.query';
import { BehaviorSubject } from 'rxjs';
import { Query_GetReleases } from 'packages/stack-web/src/app/pages/version-management/graphql/version.graphql';
import { Query_GetTeamsPaginated } from 'packages/stack-web/src/app/@core/graphql/queries/teams/getTeamsPaginated.query';
import { Query_GetDepartmentsPaginated } from 'packages/stack-web/src/app/@core/graphql/queries/locations/getDepartmentsPaginated.query';

@Injectable({
  providedIn: 'root',
})
export class QueueService {
  selectedViewGroup;
  selectedViewGroup$ = new BehaviorSubject(null);
  constructor(private readonly api: QueueApiService) {
    this.getDefaultViewGroup();
  }

  addQueue(dto: DtoAddQueue) {
    return this.api.addQueue(dto);
  }

  updateQueue(dto: DtoUpdateQueue) {
    return this.api.updateQueue(dto);
  }

  deleteQueue(id: string) {
    return this.api.deleteQueue(id);
  }

  changeViewGroup(viewGroup) {
    this.selectedViewGroup = viewGroup;
    this.selectedViewGroup$.next(viewGroup);
    if (!viewGroup) {
      localStorage.removeItem('selectedViewGroup');
      return;
    }
    localStorage.setItem('selectedViewGroup', JSON.stringify(viewGroup));
  }

  getDefaultViewGroup() {
    this.changeViewGroup(
      JSON.parse(localStorage.getItem('selectedViewGroup')) || null,
    );
  }

  getDashboardQueues() {
    const viewGroupId = this.selectedViewGroup?.id;
    const filter = [{ column: 'showOnDashboard', value: 'true' }];
    if (viewGroupId) {
      filter.push({ column: 'viewGroupId', value: `${viewGroupId}` });
    }
    const query = this.getQueryData('Queue', {
      first: 30,
      filter,
    });
    //has edges and nodes
    return query.valueChanges.pipe(
      map(({ data }) => {
        return data['getQueues'].edges.map((x, i) => {
          const y = { ...x.node };
          return y;
        });
      }),
    );
  }

  getQueuesByObjectType(dto: DtoGetQueuesByObject) {
    dto.viewGroupId = this.selectedViewGroup?.id;
    const query = this.api.getQueuesByObjectType(dto);

    const sub = query.valueChanges.pipe(
      map(({ data }) => {
        const rtn = data['getQueuesByObjectType'];
        return rtn.map((x, i) => {
          const y = { ...x };
          y['recordCount'] = -1;
          y['lastUpdated'] = new Date();
          y['pressed'] = 0;
          return y;
        });
      }),
    );
    return { query, sub };
  }

  queuesAreSame(a, b) {
    if (a.length !== b.length) {
      return false;
    }

    const bCopy = [...b];

    for (let i = 0; i < a.length; i++) {
      const aItem = a[i];
      const bIndex = bCopy.findIndex(
        (bItem) => bItem.column === aItem.column && bItem.value === aItem.value,
      );

      if (bIndex === -1) {
        return false;
      }

      bCopy.splice(bIndex, 1);
    }

    return true;
  }

  private getObjectQuery(object) {
    switch (object) {
      case 'WorkOrder':
        return QUERY_GETWORKORDERS;
      case 'Order':
        return Query_GetOrders;
      case 'Order_Packet':
        return Query_GetOrders;
      case 'Equipment':
        return QUERY_GetEquipment;
      case 'Customer':
        return QUERY_GETCUSTOMERS;
      case 'TimeSheet':
        return QUERY_GetTimeSheets;
      case 'Order_Line':
        return Query_GetOrderLines;
      case 'Contact':
        return Query_GetContacts;
      case 'Equipment_Inventory':
        return QUERY_GetEquipmentInventory;
      case 'Object_Meta_Data':
        return QUERY_GETOBJECTS;
      case 'Worker':
        return Query_GetWorkers;
      case 'Service_Visit':
        return Query_GetServiceVisits;
      case 'BlockTime':
        return Query_GetBlockTimes;
      case 'WorkOrderSegmentLine':
        return Query_GetWorkOrderSegmentLines;
      case 'WorkOrderSegment':
        return QUERY_GETWORKORDERSEGMENTS;
      case 'Service_Visit_Worker':
        return Query_GetServiceVisitWorkers;
      case 'Work_Order_Worker':
        return Query_GetWorkOrderWorkers;
      case 'Service_Visit_Work_Order':
        return Query_GetServiceVisitWorkOrder;
      case 'Checklist':
        return QUERY_GETCHECKLISTS;
      case 'ChecklistTemplate':
        return QUERY_GETCHECKLISTTEMPLATES;
      case 'Equipment_Model':
        return Query_GetEquipmentModels;
      case 'IntegrationLog':
        return Query_GetIntegrationLogs;
      case 'MobileChangeLog':
        return Query_GetMobileChangeLogs;
      case 'User':
        return QUERY_GETUSERS;
      case 'ActivityCode':
        return QUERY_GetActivityCodesPaginated;
      case 'UserRole':
        return Query_GetUserRolesPaginated;
      case 'Queue':
        return Query_GetQueues;
      case 'QueueViewGroup':
        return Query_GetQueueViewGroups;
      case 'EquipmentTransfer':
        return QUERY_GetEquipmentTransfers;
      case 'EquipmentTransferLine':
        return QUERY_GetEquipmentTransferLines;
      case 'EMR':
        return QUERY_GetEquipmentEMR;
      case 'EMRLine':
        return QUERY_GetEquipmentEMRLines;
      case 'Station':
        return Query_GetStations;
      case 'Branch':
        return Query_GetBranchesPaginated;
      case 'Location':
        return Query_GetLocationsPaginated;
      case 'Release':
        return Query_GetReleases;
      case 'Team':
        return Query_GetTeamsPaginated;
      case 'Department':
        return Query_GetDepartmentsPaginated;
    }
  }

  getQueryData(
    object,
    search,
    pollInterval = 5 * 60 * 1000,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  ) {
    const query = this.getObjectQuery(object);
    return this.api.getQueryData(search, query, pollInterval, fetchPolicy);
  }

  getQueryDataQuery(object, search) {
    const query = this.getObjectQuery(object);
    return this.api.getQueryDataQuery(search, query);
  }
}
