<div *ngIf="customer">
  <h4>{{ customer?.name }}</h4>
  <p class="text-hint">({{ customer?.type }}) {{ customer?.customerNum }}</p>
  <nb-tabset size="small" (changeTab)="onTabChange($event)">
    <nb-tab tabTitle="Details" tabIcon="file-text-outline">
      <div class="customerDetail">
        <!-- <div class="header-container">
          <span class="flex-expand"></span>
          <button
            nbButton
            hero
            size="small"
            status="primary"
            (click)="updateCustomer()"
          >
            <nb-icon icon="save-outline"></nb-icon>Save
          </button>
        </div> -->
        <nb-card>
          <nb-card-body>
            <form [formGroup]="form" autocomplete="off">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label for="inputFirstName" class="label"
                          >Customer #</label
                        >
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          id="inputFirstName"
                          formControlName="customerNum"
                          placeholder="Customer Id"
                          readonly
                          fieldSize="small"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="customername" class="label"
                          >Customer Name</label
                        >
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          id="customername"
                          formControlName="name"
                          placeholder="Customer Name"
                          fieldSize="small"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="customerType" class="label"
                            >Customer Type</label
                          >
                          <nb-select
                            fullWidth
                            formControlName="type"
                            size="small"
                          >
                            <nb-option
                              *ngFor="let type of customerTypes"
                              [value]="type.name"
                            >
                              {{ type.name }}
                            </nb-option>
                          </nb-select>
                        </div>
                      </div>
                      <div class="col-lg-5">
                        <label for="customerType" class="label"
                          >Bill To Customer#
                          <nb-icon
                            icon="search-outline"
                            status="primary"
                            style="font-size: 1.2em"
                          >
                          </nb-icon
                        ></label>
                        <next-customerinput
                          [customer]="billTo"
                          (onCustomerChange)="billToChange($event)"
                        ></next-customerinput>
                      </div>
                      <div class="col-lg-2"></div>
                      <div class="col-lg-2"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label for="externalId" class="label">TM</label>
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          id="externalId"
                          fieldSize="small"
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label for="externalId" class="label">CSS</label>
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          id="externalId"
                          fieldSize="small"
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label for="externalId" class="label"
                          >Service Advisor</label
                        >
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          id="externalId"
                          fieldSize="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="instructions" class="label"
                      >Customer Instructions</label
                    >
                    <textarea
                      nbInput
                      fullWidth
                      formControlName="instructions"
                      rows="10"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <next-address [form]="form"></next-address>
            </form>
          </nb-card-body>
        </nb-card>
      </div>
    </nb-tab>
    <nb-tab #tabWorkOrders tabTitle="Work Orders" tabIcon="clipboard-outline">
      @if (showWorkOrders) {
        <next-object-list
          [height]="600"
          (onDoubleClick)="gotoWorkOrder($event)"
          [filters]="[{ column: 'shipToId', value: customer.id }]"
          [object]="'WorkOrder'"
        ></next-object-list>
      }
    </nb-tab>
    <nb-tab #tabWorkOrders tabTitle="Service Visits" tabIcon="calendar-outline">
      @if (showServiceVisits) {
        <next-object-list
          [height]="600"
          (onDoubleClick)="gotoServiceVisit($event)"
          [filters]="[{ column: 'customer.id', value: customer.id }]"
          [object]="'Service_Visit'"
        ></next-object-list>
      }
    </nb-tab>
    <!-- <nb-tab #tabOrders tabTitle="Orders" tabIcon="clipboard-outline">
      <next-object-list
        [height]="600"
        *ngIf="tabOrders?.active"
        (onDoubleClick)="gotoOrder($event)"
        [filters]="[{ column: 'shipToId', value: customer.id }]"
        [object]="'Order'"
      ></next-object-list>
    </nb-tab> -->
    <nb-tab #tabEquipment tabTitle="Equipment" tabIcon="car-outline">
      @if (showEquipment) {
        <next-object-list
          [height]="600"
          (onDoubleClick)="gotoEquipment($event)"
          [filters]="[{ column: 'customerId', value: customer.id }]"
          [object]="'Equipment'"
        ></next-object-list>
      }
    </nb-tab>
    <nb-tab tabTitle="Contacts" tabIcon="person-outline">
      @if (showContacts) {
        <next-object-list
          [height]="600"
          [filters]="[{ column: 'customerId', value: customer.id }]"
          [object]="'Contact'"
        ></next-object-list>
      }
      <!-- <contact-list
        [height]="600"
        [filter]="[{ column: 'customerId', value: customer.id }]"
      ></contact-list> -->
    </nb-tab>
    <!-- <nb-tab tabTitle="Contracts" tabIcon="clock-outline"> Contracts </nb-tab> -->
    <!-- <nb-tab tabTitle="Portal" tabIcon="people-outline">
    <next-customer-portal></next-customer-portal>
  </nb-tab> -->
    <nb-tab tabTitle="Attachments" tabIcon="attach-outline">
      @if (showAttachments) {
        <next-attachment
          [object]="'Customer'"
          [refId]="this.customer?.id"
        ></next-attachment>
      }
    </nb-tab>
  </nb-tabset>
</div>
