import { gql } from 'apollo-angular';

export const Query_GetWorkers = gql`
  query ($search: DtoPaginatedFilter!) {
    getWorkers(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          displayName
          workerNum
          location
          team
          homeLat
          homeLng
          initials
          user {
            id
            email
            avatarURL
            hasAvatar
            initials
            firstname
            lastname
          }
        }
      }
    }
  }
`;
