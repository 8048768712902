import { gql } from 'apollo-angular';

export const QUERY_GetTimeSheets = gql`
  query ($search: DtoPaginatedFilter!) {
    getTimeSheets(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          hours
          mins
          isOpen
          isPosted
          externalId
          note
          mapURL
          manuallyEntered
          lat
          lng
          originatedExternally
          worker {
            displayName
            id
            workerNum
            team
            branch
            user {
              id
              avatarURL
            }
          }
          serviceVisitRef {
            id
            serviceVisitNum
            status
            customer {
              name
              id
              customerNum
            }
          }
          workOrderSegment {
            id
            workOrderSegmentNum
            workOrder {
              id
              make
              model
              serial
              workOrderNum
              type
              status
              shipTo {
                name
                customerNum
              }
            }
          }
          createdAt
          updatedAt
          startDateTime
          endDateTime
          qty
          activityCodeId
          activityCode {
            id
            code
            type
            fabColor
            fabIcon
            label
            showAsBlockedInScheduler
            showInSchedulerTimeline
          }
        }
      }
    }
  }
`;
