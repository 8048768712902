import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  signal,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { WorkOrderService } from '../../../../@core/services/workorder.service';
import { ServiceVisitService } from '../../../../@core/services/service-visit.service';
import { MapService } from '../../../../@core/services/map.service';
import {
  NbPopoverDirective,
  NbDatepickerDirective,
  NbWindowService,
} from '@nebular/theme';
import { GridMenuButton } from '../../../../shared/component/dynamiclistview/components/grid-menu/grid-menu.component';
import {
  SERVICE_VISIT_STATUSES,
  SERVICE_VISIT_CATEGORIES,
  buttonConfig,
} from './constants';
import { Observable, of, map, first, distinctUntilChanged } from 'rxjs';
import { DtoUpdateServiceVisit } from '../../../../@core/interfaces/DtoUpdateServiceVisit';
import {
  AttachmentComponent,
  associatedAttachmentObject,
} from '../../../attachments/components/attachment/attachment.component';
import { DynamicListViewComponent } from 'packages/stack-web/src/app/shared/component/dynamiclistview/dynamiclistview.component';
import { SchedulerService } from 'packages/stack-web/src/app/@core/services/scheduler.service';
import { Apollo } from 'apollo-angular';
import { FormsService } from 'packages/stack-web/src/app/@core/services/forms.service';
import { DtoFillServiceVisitSummaryOfWork } from 'packages/stack-web/src/app/@core/interfaces/DtoFillServiceVisitSummaryOfWork';
import { WorkOrderDetailComponent } from '../../../workorder/detail/workorderdetail.component';
import { WindowService } from 'packages/stack-web/src/app/@core/services/window.service';

@Component({
  selector: 'app-service-visit-detail',
  templateUrl: './service-visit-detail.component.html',
  styleUrls: ['./service-visit-detail.component.scss'],
})
export class ServiceVisitDetailComponent implements OnInit, OnDestroy {
  sub = new SubSink();
  @Input() set id(id: string) {
    this._id = id;
    if (id) {
      this.getServiceVisit();

      this.attachmentComponent?.getAttachments(true);
    }
  }
  get id() {
    return this._id;
  }
  _id: string;
  serviceVisit: any;
  workOrder: any;
  workOrderId_array_str: string;
  workOrderId_array_exclude_str: string;
  workerId_array_str: string = null;
  isLoading = true;
  mapRouteURL: string;
  selectedWorkOrder: any;
  selectedWorkOrdersToAdd: any[];
  selectedWorker: any;
  selectedWorkersToAdd: any[];
  selectedWorkersToDelete: any[];
  selectedServiceVisitWorkers: any[];
  selectedWorkOrdersToDelete: any[];
  menuButtons: GridMenuButton[];
  customerSignature;
  showDispatchAnimation = false;
  signatureModalVisible = false;
  currentLocation: string;
  options: string[];
  filteredOptions$: Observable<string[]>;
  serviceVisitCategories: string[];
  filters: any[];
  orFilters: any[];
  selectedStatus: string;
  scheduledStartDateTime: Date;
  scheduledEndDateTime: Date;
  minDate: Date;
  maxDate: Date;
  custClosed = signal(false);
  copied = false;
  tooltipTechnicians: any;
  tooltipWOs: string;
  segmentOrFilters: any[];
  contact;
  associatedAttachmentObjects: associatedAttachmentObject[];
  selectedWorkOrdersToSOW;
  selectedWorkOrdersToPrintSOW;
  isGenerateLoading = false;
  windowRef: any;
  activeTab: string;
  showWorkOrdersTab = false;
  showTimeSheetsTab = false;
  showAttachmentsTab = false;

  detailsShowMore = signal(false);
  canDispatchButton = signal('Loading..');
  canDeleteButton = signal('Loading..');
  canCompleteButton = signal('Loading..');
  canCancelButton = signal('Loading..');
  svSummary = signal(null);
  workOrderListQueues = [];

  @ViewChild('generateSOW') generateSOWPopOver: NbPopoverDirective;
  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  @ViewChild(NbDatepickerDirective)
  nbDatepickerDirective: NbDatepickerDirective<Date>;
  @ViewChild('autoInput') statusInput;
  @ViewChild('attachments') attachmentComponent: AttachmentComponent;
  @ViewChild('workerList') workerList: DynamicListViewComponent;
  @ViewChild('workOrderList') workOrderList: DynamicListViewComponent;

  @Input() filterServiceVisits: boolean;
  @Input() workOrderNum: any;
  tab: string;
  filter: string;
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly workOrderService: WorkOrderService,
    private readonly serviceVisitService: ServiceVisitService,
    private readonly mapService: MapService,
    private readonly schedulerService: SchedulerService,
    private readonly formService: FormsService,
    private readonly windowService: WindowService,
  ) {
    this.segmentOrFilters = [];
  }

  ngOnInit() {
    if (!this.id) {
      this.initRouteListener();
    }
    this.initMenuButtons();
    this.options = SERVICE_VISIT_STATUSES;
    this.filteredOptions$ = of(this.options);
    this.serviceVisitCategories = SERVICE_VISIT_CATEGORIES;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  changeTab(tabTitle) {
    switch (tabTitle) {
      case 'Work Orders':
        this.showWorkOrdersTab = true;
        break;
      case 'Timeline':
        this.showTimeSheetsTab = true;
        break;
      case 'Attachments':
        this.showAttachmentsTab = true;
        break;
    }
  }

  viewSignature() {
    this.sub.sink = this.serviceVisitService
      .getCustomerSignatureByServiceVisitId(this.serviceVisit.id)
      .valueChanges.subscribe(({ data }) => {
        this.customerSignature = (<any>(
          data
        ))?.getCustomerSignaturesByServiceVisitId;
      });
  }

  setupWorkOrderListQueues() {
    const equipmentNums = this.serviceVisit?.serviceVisitWorkOrders?.map(
      ({ workOrder }) => workOrder?.equipment?.equipmentNum,
    );
    const rentalContracts = this.serviceVisit?.serviceVisitWorkOrders?.map(
      ({ workOrder }) => workOrder?.equipment?.rentalContractNum,
    );
    const equipmentIdStr = equipmentNums.join(',');
    const rentalContractStr = rentalContracts.join(',');
    this.workOrderListQueues = [
      {
        name: 'Customer',
        filter: [
          {
            column: 'shipTo.customerNum',
            value: this.serviceVisit?.customer?.customerNum,
          },
          { column: 'isClosed', value: 'false' },
          { column: 'workOrderNum', value: this.workOrderId_array_exclude_str },
        ],
      },
    ];

    if (equipmentIdStr.trim() !== '') {
      this.workOrderListQueues.push({
        name: 'Equipment',
        filter: [
          { column: 'equipment.equipmentNum', value: equipmentIdStr },
          { column: 'isClosed', value: 'false' },
          { column: 'workOrderNum', value: this.workOrderId_array_exclude_str },
        ],
      });
    }

    if (rentalContractStr.trim() !== '') {
      this.workOrderListQueues.push({
        name: 'Rental Contract',
        filter: [
          {
            column: 'equipment.rentalContractNum',
            value: `!null,${rentalContractStr}`,
          },
          { column: 'isClosed', value: 'false' },
          { column: 'workOrderNum', value: this.workOrderId_array_exclude_str },
        ],
      });
    }
  }

  initRouteListener() {
    this.sub.sink = this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getServiceVisit();
      }
    });
  }

  receiveWorkerRecords(d: any) {
    console.log('worker record:', d);
  }

  isTooltipDisabled(action: string): boolean {
    return !this.isButtonDisabled(action);
  }

  isButtonDisabled(action: string): boolean {
    const status = this.serviceVisit?.status;

    if (
      !status ||
      !buttonConfig[status] ||
      buttonConfig[status][action] === undefined
    ) {
      return true;
    }
    // Special cases
    const svwInPlannedStatus = this.serviceVisit?.serviceVisitWorkers?.some(
      (svw) => svw.status === 'Planned',
    );
    if (action === 'dispatch' && !svwInPlannedStatus) return true;

    const svTimeSheets = this.serviceVisit?.timeSheets;
    if (action === 'cancel' && svTimeSheets?.length > 0) return true;

    const svOpenTimeSheets = svTimeSheets?.filter((ts) => ts.isOpen === true);
    if (action === 'complete' && svOpenTimeSheets?.length > 0) return true;

    return buttonConfig[status][action] !== '';
  }

  getButtonTooltip(action: string): string {
    const status = this.serviceVisit?.status;

    if (
      !status ||
      !buttonConfig[status] ||
      buttonConfig[status][action] === undefined
    ) {
      return '';
    }
    // Special cases
    const svwInPlannedStatus = this.serviceVisit?.serviceVisitWorkers?.some(
      (svw) => svw.status === 'Planned',
    );
    if (action === 'dispatch' && !svwInPlannedStatus)
      return 'Nothing to dispatch';

    const svTimeSheets = this.serviceVisit?.timeSheets;
    if (action === 'cancel' && svTimeSheets?.length > 0)
      return `Cannot cancel a service visit with ${svTimeSheets?.length} time sheets.`;

    const svOpenTimeSheets = svTimeSheets?.filter((ts) => ts.isOpen === true);
    if (action === 'complete' && svOpenTimeSheets?.length > 0)
      return `Cannot complete a service visit with open time sheets.`;

    return buttonConfig[status][action];
  }

  initMenuButtons() {
    this.menuButtons = [
      {
        type: 'button',
        label: 'Add Technician',
        icon: 'plus-outline',
      },
    ];
  }

  updateServiceVisitStatus(
    svIds: Array<string> | string,
    status,
    notifyWorkers = true,
  ) {
    const ids = svIds || [this.id];
    this.selectedStatus = status;
    const dto = {
      ids: ids,
      status,
      notifyWorkers,
    };
    this.sub.sink = this.serviceVisitService
      .updateServiceVisitStatus(dto)
      .subscribe((res) => {
        this.getServiceVisit();
      });
  }

  copyURL() {
    const url = this.serviceVisit?.shareLinkURL;
    this.copied = true;
    navigator.clipboard.writeText(url);
  }

  async deleteServiceVisit() {
    this.sub.sink = this.schedulerService
      .deleteServiceVisits([this.serviceVisit.id])
      .pipe(first())
      .subscribe({
        next: () => {
          if (this.router.url.includes('scheduler')) {
            this.router.navigate(['/pages/scheduler']);
          }
        },
        error: (err) => {
          console.error('Error deleting service visit:', err);
        },
      });
  }

  async cancelServiceVisit(
    svIds: Array<string> | string,
    status,
    notifyWorkers = true,
  ) {
    const ids = svIds || [this.id];
    this.selectedStatus = status;
    const dto = {
      ids: ids,
      status,
      notifyWorkers,
    };
    this.sub.sink = this.schedulerService
      .cancelServiceVisits(dto)
      .pipe(first())
      .subscribe({
        error: (err) => {
          console.error('Error canceling service visit:', err);
        },
      });
  }

  setSelectedWorkOrdersToPrintSOW(workOrders) {
    this.selectedWorkOrdersToPrintSOW = workOrders;
  }

  async updateServiceVisitField(fieldName, $event) {
    const dto: DtoUpdateServiceVisit = {
      id: this.serviceVisit.id,
      [fieldName]: $event,
    };
    await this.workOrderService.updateServiceVisit(dto).toPromise();
  }

  async shipToChange(shipTo) {
    const dto: DtoUpdateServiceVisit = {
      id: this.serviceVisit.id,
      customerNum: shipTo.customerNum,
      address1: shipTo.address1,
      address2: shipTo.address2,
      zipcode: shipTo.zipcode,
      city: shipTo.city,
      state: shipTo.state,
      country: shipTo.country,
      lat: shipTo.lat,
      lng: shipTo.lng,
    };
    await this.workOrderService.updateServiceVisit(dto).toPromise();
  }

  doAnimation() {
    // document.getElementById("anii").classList.add("anii");
  }

  onIndexChange(event) {
    console.log(event);
  }

  onSelectAddWorkers(workers) {
    this.selectedWorkersToAdd = workers;
  }

  onSelectWorkersToDelete(serviceVisitWorkers) {
    if (!serviceVisitWorkers) {
      return;
    }
    this.selectedWorkersToDelete = serviceVisitWorkers.map(
      (svw) => svw.workerId,
    );

    this.selectedServiceVisitWorkers = serviceVisitWorkers;
  }

  onSelectWorker(worker) {
    this.selectedWorker = worker;
  }

  async deleteWorkers() {
    const workerIdsToDelete = this.serviceVisit.serviceVisitWorkers
      .filter((worker) =>
        this.selectedWorkersToDelete.some(
          (selectedWorkerId) => selectedWorkerId === worker.worker.id,
        ),
      )
      .map((worker) => worker.id);

    await this.serviceVisitService
      .deleteWorkersFromServiceVisit(workerIdsToDelete)
      .toPromise();
    this.workerList.refreshGrid();
  }

  removeWorkOrder() {
    const workOrderIdsToDelete = this.selectedWorkOrdersToDelete.map(
      (svwo) => svwo?.id,
    );
    return this.workOrderService.deleteWorkOrdersFromServiceVisit(
      workOrderIdsToDelete,
    );
  }

  workOrderAddOnSelect(workOrders) {
    this.selectedWorkOrdersToAdd = workOrders;
  }

  onSelectWorkOrder(workOrder) {
    this.selectedWorkOrder = workOrder;
  }

  onSelectWorkOrdersToDelete(workOrders: string[]) {
    this.selectedWorkOrdersToDelete = workOrders;
  }

  async showDirectionsMap() {
    const { lat, lng } = this.serviceVisit;
    const fromLatLngStr = this.serviceVisit.estTravelFromLatLng;
    const polyline = this.serviceVisit.estTravelPolyline;

    if (!fromLatLngStr) {
      this.mapRouteURL = null;
      return;
    }

    const fromLatLng = [
      fromLatLngStr.split(',')[1],
      fromLatLngStr.split(',')[0],
    ];

    const toLatLng = [lng, lat];

    const directions = await this.mapService.staticUrlMapPointAtoB(
      fromLatLng,
      toLatLng,
      polyline,
      { x: 300, y: 300 },
    );

    this.mapRouteURL = directions;
  }

  getServiceVisit() {
    this.isLoading = true;
    this.sub.sink = this.workOrderService
      .getServiceVisitById(this.id)
      .pipe(distinctUntilChanged())
      .subscribe(({ data }) => {
        console.log('sub FIRE');
        this.serviceVisit = { ...data['getServiceVisit'] };
        if (this.serviceVisit) {
          this.isLoading = false;
        }

        this.contact = this.serviceVisit?.contact;
        this.scheduledStartDateTime = new Date(
          this.serviceVisit.scheduledStartDateTime,
        );
        this.scheduledEndDateTime = new Date(
          this.serviceVisit.scheduledEndDateTime,
        );
        this.minDate = new Date(this.serviceVisit.scheduledStartDateTime);
        this.maxDate = new Date(this.serviceVisit.scheduledEndDateTime);

        if (this.windowRef) {
          this.windowRef.config.title = `${this.serviceVisit?.serviceVisitNum || ''} - ${this.serviceVisit?.customer?.name || 'UNPLANNED'}`;
        }

        this.associatedAttachmentObjects =
          this.serviceVisit?.serviceVisitWorkOrders?.map(({ workOrder }) => ({
            refId: workOrder.id,
            object: 'WorkOrder',
            label: workOrder.workOrderNum,
          }));

        const workOrderIds = this.serviceVisit?.serviceVisitWorkOrders
          .map((swo) => swo.workOrder.id)
          .join(',');

        this.segmentOrFilters = [
          {
            column: 'workOrderId',
            value: workOrderIds || 'null',
          },
        ];

        if (this.workOrderNum) {
          this.segmentOrFilters = [
            {
              column: 'workOrder.workOrderNum',
              value: this.workOrderNum,
            },
          ];
        }

        this.workOrderId_array_str = this.serviceVisit?.serviceVisitWorkOrders
          ?.map(({ workOrder }) => {
            return workOrder.id;
          })
          .join(',');

        this.selectedWorkOrdersToSOW = this.serviceVisit?.serviceVisitWorkOrders
          ?.filter(
            (svwo) =>
              svwo?.workOrderStatusRef?.isCompleteOnServiceVisit === true,
          )
          .map(({ id }) => {
            return id;
          });

        this.workOrderId_array_exclude_str =
          '!' +
          this.serviceVisit?.serviceVisitWorkOrders
            ?.map(({ workOrder }) => {
              return workOrder.workOrderNum;
            })
            .join(',!');

        this.workerId_array_str = this.serviceVisit?.serviceVisitWorkers
          ?.map(({ worker }) => {
            return worker.id;
          })
          .join(',');

        const workers = this.serviceVisit?.serviceVisitWorkers;
        const workOrders = this.serviceVisit?.serviceVisitWorkOrders;

        this.tooltipWOs = '';
        this.tooltipTechnicians = '';

        for (let i = 3; i < workers.length; i++) {
          this.tooltipTechnicians += workers[i].worker.workerNum + ', ';
        }

        for (let i = 3; i < workOrders.length; i++) {
          this.tooltipWOs += workOrders[i].workOrder.workOrderNum + ', ';
        }
        this.setupWorkOrderListQueues();
      });
  }

  async addWorkOrdersToServiceVisit() {
    const workOrderIds = this.selectedWorkOrdersToAdd.map(({ id }) => id);
    this.closePopover();
    const rtn = await this.workOrderService
      .addWorkOrderToServiceVisit({
        id: this.id,
        workOrderIds,
      })
      .toPromise();

    this.workOrderList.refreshGrid();
  }

  async addWorkersToServiceVisit() {
    if (!this.selectedWorkersToAdd) {
      return;
    }

    const workerIds = this.selectedWorkersToAdd.map(({ id }) => id);
    this.closePopover();

    await this.serviceVisitService
      .addWorkersToServiceVisit(this.serviceVisit.id, workerIds)
      .toPromise();
    this.workerList.refreshGrid();
  }

  async onDateTimeChange(updatedStartDate: Date, updatedEndDate: Date) {
    const dto: DtoUpdateServiceVisit = {
      id: this.serviceVisit.id,
      scheduledStartDateTime: updatedStartDate,
      scheduledEndDateTime: updatedEndDate,
    };
    await this.workOrderService.updateServiceVisit(dto).toPromise();
  }

  showWorkOrderDetail(workOrder: any) {
    // this.router.navigate([{ outlets: { right: ['segment', workOrder.id] } }], {
    //   relativeTo: this.route.parent,
    // });
    this.windowService.openWindow(WorkOrderDetailComponent, {
      objectId: workOrder.id,
      context: {
        id: workOrder.id,
      },
    });
  }

  onCustCollapseChange(event: any) {
    this.custClosed.set(event);
  }

  closePopover() {
    this.popovers.forEach((pop) => {
      pop.hide();
    });
  }

  toggleDetailsShowMore(event: any) {
    this.detailsShowMore.set(!this.detailsShowMore());
    event.stopPropagation();
  }

  getWorkOrder(id: string) {
    if (!id) {
      return;
    }
    this.sub.sink = this.workOrderService
      .getWorkOrder(id)
      .subscribe((_workorder) => {
        const workorder: any = _workorder;
        this.workOrder = workorder.data.getWorkOrder;
      });
  }

  generateSvSummary() {
    const serviceVisits = [this.serviceVisit];

    const summary = {
      serviceVisitCount: 0,
      totalTravelTime: 0,
      totalWorkTime: 0,
      workers: [
        {
          name: '',
          serviceVisits: [
            {
              serviceVisitNum: '',
              totalTravelTime: 0,
              totalWorkTime: 0,
            },
          ],
        },
      ],
    };

    //get distinct count of service visit numbers;
    summary.serviceVisitCount = [
      ...new Set(serviceVisits.map((sv) => sv.serviceVisitNum)),
    ].length;

    //FIX this to be at the worker line level after we have proper worker data
    summary.totalTravelTime =
      serviceVisits.reduce((acc, sv) => acc + sv.estTravelTime, 0) || 0;

    summary.totalWorkTime =
      serviceVisits.reduce((acc, sv) => acc + sv.duration, 0) || 0;

    serviceVisits.forEach((sv) => {
      const worker = summary.workers.find(
        (w) => w.name === sv.worker?.displayName,
      );

      if (!worker) {
        summary.workers.push({
          name: sv.worker?.displayName,
          serviceVisits: [
            {
              serviceVisitNum: sv.serviceVisitNum,
              totalTravelTime: sv.estTravelTime,
              totalWorkTime: sv.duration,
            },
          ],
        });
      } else {
        worker.serviceVisits.push({
          serviceVisitNum: sv.serviceVisitNum,
          totalTravelTime: sv.estTravelTime,
          totalWorkTime: sv.duration,
        });
      }
    });

    this.svSummary.set(summary);
  }

  async contactChange(contact: any, emitEvent = true) {
    this.contact = contact;
    this.serviceVisit.contact = contact;
    await this.updateServiceVisitField('contactId', contact.id);
  }

  generateSOWDocument(popover: any) {
    if (this.isGenerateLoading || !this.selectedWorkOrdersToPrintSOW) {
      return;
    }
    this.isGenerateLoading = true;
    const ids = this.selectedWorkOrdersToPrintSOW?.map(({ id }) => id);
    if (!ids || ids.length === 0) {
      return;
    }
    const dto: DtoFillServiceVisitSummaryOfWork = {
      serviceVisitId: this.id,
      serviceVisitWorkOrderIds: ids,
    };

    this.sub.sink = this.formService
      .fillForm_ServiceVisitSummaryOfWork(dto)
      .subscribe((res) => {
        this.closePopover();
        this.isGenerateLoading = false;
        this.attachmentComponent.refreshAll();
      });
  }
}
