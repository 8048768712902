import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { QUERY_GetDefaultActivityWork } from '../../../@core/graphql/queries/timemanagment/getDefaultActivityWork.query';
import { DtoAddTimeSheet } from '../../../@core/interfaces/DtoAddTimeSheet';
import { MUTATION_AddTimeSheet } from '../../../@core/graphql/mutations/timemanagment/addTimeSheet.mutation';
import { QUERY_GetTimeSheetsBySegmentId } from '../../../@core/graphql/queries/timemanagment/getTimeSheetsBySegmentId.query';
import { QUERY_GetTimeSheets } from '../../../@core/graphql/queries/timemanagment/getTimeSheets.query';
import { QUERY_GetSumTimeSheets } from '../../../@core/graphql/queries/timemanagment/getSumTimeSheets.query';
import { QUERY_GetFabActivityCodes } from '../../../@core/graphql/queries/timemanagment/getFabActivityCodes.query';
import { DtoStartActivityCode } from '../../../@core/interfaces/DtoStartActivityCode';
import { Mutation_StartActivityCode } from '../../../@core/graphql/mutations/timemanagment/startActivityCode.mutation';
import { Mutation_StopActivityCode } from '../../../@core/graphql/mutations/timemanagment/stopActivityCode.mutation';
import { Query_GetMyOpenTimeSheet } from '../../../@core/graphql/queries/timemanagment/getMyOpenTimeSheet.query';
import { map } from 'rxjs/operators';
import { Query_GetMyTeamsLastTimeSheet } from '../../../@core/graphql/queries/timemanagment/getMyTeamsLastTimeSheets.quer';
import { QUERY_GetActivityCodes } from '../../../@core/graphql/queries/timemanagment/getActivityCodes.query';
import { IPaginatedFilter } from '../../../@core/interfaces/IPaginatedFilter';
import { Query_GetTeamsLastTimeSheets } from '../../../@core/graphql/queries/timemanagment/getTeamsLastTimeSheet.query';
import { MUTATION_AddTimeSheets } from '../../../@core/graphql/mutations/timemanagment/addTimeSheets.mutation';
import { Mutation_DeleteTimeSheets } from '../../../@core/graphql/mutations/timemanagment/deleteTimeSheets.mutation';
import { DtoUpdateTimeSheet } from '../../../@core/interfaces/DtoUpdateTimeSheet';
import { MUTATION_UpdateTimeSheet } from '../../../@core/graphql/mutations/timemanagment/updateTimeSheet.mutation';
import { QUERY_GetTimeSheetById } from '../../../@core/graphql/queries/timemanagment/getTimeSheetById.query';

@Injectable({
  providedIn: 'root',
})
export class TimeManagmentApiService {
  constructor(private apollo: Apollo) {}

  getMyTeamsLastTimeSheet() {
    return this.apollo.watchQuery({
      query: Query_GetMyTeamsLastTimeSheet,
      pollInterval: 90000,
    }).valueChanges;
  }

  getTeamsLastTimeSheet(teamNums: string[], branchNum: string) {
    return this.apollo.watchQuery({
      query: Query_GetTeamsLastTimeSheets,
      variables: { teamNums, branchNum },
      pollInterval: 90000,
    });
  }

  getActivityCodes() {
    return this.apollo.query({
      query: QUERY_GetActivityCodes,
    });
  }

  getTimeSheetById(id: string) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: QUERY_GetTimeSheetById,
      variables: { id },
    });
  }

  getFabActivityCodes() {
    return this.apollo.query({
      query: QUERY_GetFabActivityCodes,
    });
  }

  getDefaultActivityWork() {
    return this.apollo.query({
      query: QUERY_GetDefaultActivityWork,
    });
  }

  getTimeSheets(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: QUERY_GetTimeSheets,
      fetchPolicy: 'cache-and-network',
      variables: { search: search },
      pollInterval: 60000,
    });
  }

  getMyOpenTimeSheet() {
    return this.apollo.watchQuery({
      query: Query_GetMyOpenTimeSheet,
    }).valueChanges;
  }

  getSumTimeSheets(search: IPaginatedFilter, pollInterval = null) {
    return this.apollo.watchQuery({
      query: QUERY_GetSumTimeSheets,
      fetchPolicy: 'cache-and-network',
      variables: { search: search },
      pollInterval,
    }).valueChanges;
  }

  // startActivityCode(data: DtoStartActivityCode) {
  //   return this.apollo.mutate({
  //     mutation: Mutation_StartActivityCode,
  //     variables: { data },
  //     refetchQueries: [
  //       {
  //         query: Query_GetMyOpenTimeSheet,
  //       },
  //       {
  //         query: Query_GetWorkOrderBySegmentId,
  //         variables: {
  //           id: data.workOrderSegmentId,
  //         },
  //       },
  //     ],
  //   });
  // }

  stopActivityCode() {
    return this.apollo.mutate({
      mutation: Mutation_StopActivityCode,
      refetchQueries: [
        {
          query: Query_GetMyOpenTimeSheet,
        },
      ],
    });
  }

  addTimeSheet(data: DtoAddTimeSheet) {
    return this.apollo.mutate({
      mutation: MUTATION_AddTimeSheet,
      variables: { data },
    });
  }

  updateTimeSheet(dto: DtoUpdateTimeSheet) {
    return this.apollo.mutate({
      mutation: MUTATION_UpdateTimeSheet,
      variables: { dto },
    });
  }

  deleteTimeSheets(ids: string[]) {
    return this.apollo.mutate({
      mutation: Mutation_DeleteTimeSheets,
      variables: { ids },
      update: (cache) => {
        ids.forEach((id) => {
          cache.evict({ id: `TimeSheet:${id}` });
        });
        cache.gc();
      },
    });
  }

  addTimeSheets(ts: DtoAddTimeSheet[]) {
    const dto = {
      timesheets: ts,
    };
    return this.apollo.mutate({
      mutation: MUTATION_AddTimeSheets,
      variables: { dto },
      errorPolicy: 'all',
    });
  }

  getTimeSheetsBySegmentId(id: string) {
    return this.apollo.watchQuery({
      fetchPolicy: 'cache-and-network',
      query: QUERY_GetTimeSheetsBySegmentId,
      variables: { id },
    }).valueChanges;
  }
}
