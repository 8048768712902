import { gql } from 'apollo-angular';

export const Query_GetTeamsPaginated = gql`
  query getTeamsPaginated($search: DtoPaginatedFilter!) {
    getTeamsPaginated(search: $search) {
      edges {
        node {
          id
          name
          tenantId
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
