<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      @if (user?.tenantName) {
        <img height="40px" src="assets/img/stack-logo.png" alt="Stack Logo" />
      } @else {
        <img
          height="40px"
          src="assets/img/stack-logo-name.png"
          alt="Stack Logo"
        />
      }
    </a>

    <div class="tenant-name-container">
      <p class="tenant-name">{{ user?.tenantName }}</p>
    </div>
  </div>
  <!-- <nb-select
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
    status="primary"
  >
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option
    >
  </nb-select> -->
</div>

<div class="header-container">
  @if (user) {
    <nb-actions size="medium">
      <nb-action
        class="control-item chat"
        icon="message-circle-outline"
        title="Messaging"
        [nbPopover]="list"
        nbPopoverPlacement="bottom"
        badgePosition="top right"
        [badgeText]="unreadMessaegeCount"
        badgeStatus="danger"
      ></nb-action>
      <nb-action class="user-action" *nbIsGranted="['view', 'user']">
        <nb-user
          class="user-avatar"
          shape="round"
          [nbContextMenu]="userMenu"
          nbContextMenuTag="userMenu"
          [onlyPicture]="userPictureOnly"
          [name]="user?.name"
          [picture]="user?.avatarURL"
        >
        </nb-user>
      </nb-action>
    </nb-actions>
  } @else {
    <nb-actions>
      <nb-action
        class="control-item"
        style="cursor: pointer"
        title="Login"
        (click)="login()"
        (keydown.enter)="login()"
        status="primary"
      >
        <nb-icon icon="log-in-outline"></nb-icon>
        &nbsp; Login
      </nb-action>
    </nb-actions>
  }
</div>

<ng-template #list>
  <nb-card style="width: 310px">
    <!-- <nb-list-item> <b>Dispatch Messaging </b> </nb-list-item> -->
    <nb-list>
      <nb-list-item
        *ngFor="let session of teamMessages$ | async"
        (click)="openChatSession(session)"
        class="clickable"
      >
        <nb-user
          [name]="session?.teamName"
          [title]="session?.lastMessageRef?.message"
          badgePosition="top right"
          [badgeText]="session?.unreadMessageCount"
          badgeStatus="danger"
        >
        </nb-user>
      </nb-list-item>
    </nb-list>
    <nb-list-item>
      <b>Direct Messaging</b>
    </nb-list-item>
    <nb-list>
      <nb-list-item
        *ngFor="let session of directMessages$ | async"
        class="clickable"
      >
        <nb-user
          (click)="openChatSession(session)"
          [picture]="session?.recipentUser?.avatarURL"
          [badgeText]="session?.unreadMessageCount"
          badgeStatus="danger"
          shape="semi-round"
          badgePosition="top right"
          [name]="session?.recipentUser?.fullName"
          [title]="session?.lastMessageRef?.message"
        ></nb-user>
        <span></span>
        <button nbButton ghost size="small" (click)="hideSession(session)">
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </nb-list-item>
    </nb-list>
  </nb-card>
</ng-template>
