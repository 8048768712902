import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceVisitTimelineComponent } from './service-visit-timeline.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ServiceVisitTimelineComponent],
  exports: [ServiceVisitTimelineComponent]
})
export class ServiceVisitTimelineModule {}