import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DtoAddAttachment } from '../../../@core/interfaces/DtoAddAttachment';

import { AttachmentComponent } from '../components/attachment/attachment.component';
import { AttachmentApiService } from './attachment-api.service';
//RR import { DocumentScanner } from '@awesome-cordova-plugins/document-scanner/ngx';
//import { DocumentScanner, DocumentScannerOptions } from '@awesome-cordova-plugins/document-scanner';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  constructor(private readonly attachmentService: AttachmentApiService) {}

  removeAttachment(id: string) {
    return this.attachmentService.removeAttachment(id);
  }

  async addAttachment(dto: DtoAddAttachment, uri: any = null) {
    if (!uri) {
      return;
    }

    const { data } = await this.attachmentService
      .addAttachment(dto)
      .toPromise();
    const attachmentRec = (<any>data).addAttachment;
    const { id, preSignedURL } = attachmentRec;
    let isError = false;
    let errorMessage = null;
    try {
      const _ = await this.addAttachmentToS3(uri, preSignedURL).toPromise();
    } catch (e) {
      isError = true;
      errorMessage = e?.message;
      return null;
    } finally {
      await this.ackAttachment(id, isError, errorMessage).toPromise();
    }
    return attachmentRec;
  }

  ackAttachment(attachmentId: string, isError: boolean, errorMessage: string) {
    const dto = {
      attachmentId,
      isError,
      errorMessage,
    };
    return this.attachmentService.acknowledgeAttachment(dto);
  }

  addAttachmentToS3(file, url) {
    return this.attachmentService.addAttachmentToS3(file, url);
  }

  getAttachments(refId: string, object: string) {
    return this.attachmentService.getAttachments(refId, object);
  }

  //async getCameraImage(showScanner = false) {}

  async getBase64ImageFromUrl(imageUrl): Promise<any> {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false,
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
}
