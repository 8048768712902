import { gql } from 'apollo-angular';

export const Mutation_AddWorkersToWorkOrder = gql`
  mutation addWorkersToWorkOrder(
    $workOrderId: String!
    $workerNums: [String!]!
  ) {
    addWorkersToWorkOrder(workOrderId: $workOrderId, workerNums: $workerNums) {
      id
    }
  }
`;
