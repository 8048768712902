import { gql } from 'apollo-angular';

export const Query_GetScheduledServiceVisits = gql`
  query ($data: DtoGetScheduledServiceVisits!) {
    getServiceVisitByWorkerIds(data: $data) {
      id
      serviceVisitNum
      status
      address
      address1
      address2
      city
      state
      country
      category
      lat
      lng
      mapURL
      scheduledStartDateTime
      scheduledEndDateTime
      estTravelDistance
      estTravelTime
      estTravelFromLatLng
      estTravelPolyline
      completedWorkOrderCount
      totalWorkOrderCount
      workerViewed
      groupId
      isActive
      statusRef {
        id
        color
      }
      customer {
        id
        name
        customerNum
      }
      serviceVisitWorkers {
        id
        status
        estTravelDistance
        estTravelTime
        estTravelFromLatLng
        estTravelPolyline
        scheduledStartDateTime
        scheduledEndDateTime
        statusRef {
          id
          color
        }
        worker {
          displayName
          workerNum
          id
          user {
            id
            email
            avatarURL
          }
        }
      }
      serviceVisitWorkOrders {
        id
        workOrder {
          id
          workOrderNum
          subject
          status
          type
        }
      }
    }
  }
`;
