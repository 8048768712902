import { gql } from 'apollo-angular';

export const QUERY_GETCHECKLISTTEMPLATES = gql`
  query ($search: DtoPaginatedFilter!) {
    getChecklistTemplates(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          name
          json
          name
          description
          requiresEquipment
          category
          requestType
          group
          hidden
        }
      }
    }
  }
`;
