import {
  Component,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
  signal,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WorkOrderService } from '../../../@core/services/workorder.service';
import { DynamicListViewComponent } from '../../../shared/component/dynamiclistview/dynamiclistview.component';
import { SubSink } from 'subsink';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ngx-workorderdetail',
  templateUrl: './workorderdetail.component.html',
  styleUrls: ['./workorderdetail.component.scss'],
})
export class WorkOrderDetailComponent implements OnDestroy {
  //create a input id setter and getter
  private _id: string;
  @Input() set id(id: string) {
    this._id = id;
    if (!id) return;
    this.getWorkOrder(id);
  }
  get id() {
    return this._id;
  }
  subs = new SubSink();
  workorder: any;
  error: string;
  paramSub$;
  shipTo: any;
  billTo: any;
  equipment: any;
  activeTab = 'Details';
  custClosed = signal(false);
  equipClosed = signal(false);
  woDetailsClosed = signal(false);
  selectedStatus = '';
  selectedCategory = '';
  selectedPriority = '';
  woheaderfixed = false;
  offsetTop = 0;
  custShowMore = signal(false);
  equipShowMore = signal(false);
  woShowMore = signal(false);
  selectedWorkersToAdd = [];
  selectedWorkersToDelete = [];
  @ViewChild('woAccordion') woAccordion;
  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  @ViewChild('workerList') workerList: DynamicListViewComponent;
  statusList = [];
  loadingStates: { [key: string]: boolean } = {};
  selectedServiceVisit;
  windowRef;

  // Tab visibility flags
  showSegments = false;
  showServiceVisits = false;
  showResources = false;
  showChecklists = false;
  showAttachments = false;

  form = new FormGroup({
    workOrderNum: new FormControl(''),
    createdAt: new FormControl(''),
    updatedAt: new FormControl(''),
    createdBy: new FormControl(''),
    updatedBy: new FormControl(''),
    shipToId: new FormControl(''),
    billToId: new FormControl(''),
    location: new FormControl(''),
    locationNum: new FormControl(''),
    branch: new FormControl(''),
    branchNum: new FormControl(''),
    departmentNum: new FormControl(''),
    equipment: new FormControl(''),
    externalId: new FormControl(''),
    workerId: new FormControl(''),
    pastdue: new FormControl(''),
    address: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zipcode: new FormControl(''),
    country: new FormControl(''),
    lat: new FormControl(''),
    lng: new FormControl(''),
    department: new FormControl(''),
    priority: new FormControl(''),
    subject: new FormControl(''),
    serviceDescription: new FormControl(''),
    note_problemDesc: new FormControl(''),
    note_Findings: new FormControl(''),
    note_Corrections: new FormControl(''),
    note_internal: new FormControl(''),
    scheduledStartDateTime: new FormControl(''),
    scheduledEndDateTime: new FormControl(''),
    isClosed: new FormControl(''),
    isMissed: new FormControl(''),
    isPastDue: new FormControl(''),
    pastDueCount: new FormControl(''),
    missedCount: new FormControl(''),
    rescheduleCount: new FormControl(''),
    duration: new FormControl(''),
    totalTime: new FormControl(''),
    poNumber: new FormControl(''),
    customerEquipmentNum: new FormControl(''),
    poType: new FormControl(''),
    serial: new FormControl(''),
    make: new FormControl(''),
    model: new FormControl(''),
    equipmentId: new FormControl(''),
    WorkOrderSegments: new FormControl(''),
    lastLabor: new FormControl(''),
    type: new FormControl(''),
    category: new FormControl(''),
    status: new FormControl(''),
    statusRef: new FormControl(''),
    tags: new FormControl(''),
    contactName: new FormControl(''),
    contactPhone: new FormControl(''),
    contactEmail: new FormControl(''),
    worker: new FormControl(''),
    isMachineDown: new FormControl(''),
    objectType: new FormControl(''),
    mapURL: new FormControl(''),
    estTravelTime: new FormControl(''),
    estTravelDistance: new FormControl(''),
    estTravelHash: new FormControl(''),
    estTravelFromLatLng: new FormControl(''),
    serviceVisitWorkOrders: new FormControl(''),
    workerNames: new FormControl(''),
  });

  constructor(
    private readonly workOrderService: WorkOrderService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly toaster: NbToastrService, //private envService: EnvService
  ) {
    this.setupParamSub();
    this.setupQueryParamSub();
    this.getWorkOrderStatuses();
    this.subs.add(
      this.form
        .get('poNumber')
        .valueChanges.pipe(skip(1), debounceTime(1000), distinctUntilChanged())
        .subscribe((value) => {
          this.updateCustomerPO(value);
        }),
    );
  }

  ngOnDestroy() {
    if (this.paramSub$) this.paramSub$.unsubscribe();
    this.subs.unsubscribe();
  }

  setupParamSub() {
    if (this.id) {
      return;
    }
    this.paramSub$ = this.activatedRoute.params.subscribe((params) => {
      const id = params['id'];
      this.getWorkOrder(id);
    });
  }

  toggleCustShowMore(event) {
    this.custShowMore.set(!this.custShowMore());
    event.stopPropagation();
  }

  toggleWOShowMore(event) {
    this.woShowMore.set(!this.woShowMore());
    event.stopPropagation();
  }

  toggleEquipShowMore(event) {
    this.equipShowMore.set(!this.equipShowMore());
    event.stopPropagation();
  }

  setupQueryParamSub() {
    // this.activatedRoute.queryParamMap.subscribe((map: any) => {
    //   this.activeTab = map.params.tab;
    // });
  }

  getWorkOrder(id: string) {
    if (!id) return;
    this.workOrderService.getWorkOrder(id).subscribe((_workorder) => {
      const workorder: any = _workorder;
      this.workorder = workorder.data.getWorkOrder;
      this.shipTo = this.workorder.shipTo;
      this.billTo = this.workorder.billTo;
      this.equipment = this.workorder.equipment;
      this.form.patchValue(this.workorder);
      this.selectedCategory = this.workorder.category;
      this.selectedStatus = this.workorder.status;
      if (this.windowRef) {
        this.windowRef.config.title = `${this.workorder?.workOrderNum} - ${this.workorder?.shipTo?.name}`;
      }
    });
  }

  shipToChange(customer: any) {
    this.shipTo = customer;
    const { address1, address2, city, state, zipcode, country, id } = customer;

    this.form.patchValue({
      address1,
      address2,
      city,
      state,
      zipcode,
      country,
      shipToId: id,
    });

    if (this.shipTo && this.shipTo.billTo) {
      this.billToChange(this.shipTo.billTo);
      return;
    }
    if (this.shipTo.type === 'CASH') {
      this.billToChange(this.shipTo);
      return;
    }
  }

  billToChange(customer: any) {
    this.billTo = customer;
    this.form.controls['billToId'].setValue(customer.id);
  }

  equipmentChange(equipment) {
    this.equipment = equipment;
    if (!equipment) return;
    if (equipment.customer) {
      this.shipToChange(equipment.customer);
    }

    const { make, model, serial, customerEquipmentNum, id } = this.equipment;

    this.form.patchValue({
      make,
      model,
      serial,
      customerEquipmentNum,
      equipmentId: id,
    });
  }

  updateWorkOrder() {
    const obj: any = this.form.value;
    obj.shipToId = this.shipTo?.id;
    obj.billToId = this.billTo?.id;
    obj.equipmentId = this.equipment?.id ?? null;
    obj.workerId = undefined;

    this.workOrderService.updateWorkOrder(obj).subscribe(
      (rnt) => {
        this.toaster.primary('Saved!', this.workorder.workOrderNum);
      },
      (err) => {
        this.error = err;
      },
    );
  }

  onTabChange(tab: any) {
    this.activeTab = tab.tabTitle;

    // Set visibility flag for the selected tab to true if not already set
    switch (tab.tabTitle) {
      case 'Segments':
        if (!this.showSegments) this.showSegments = true;
        break;
      case 'Service Visits':
        if (!this.showServiceVisits) this.showServiceVisits = true;
        break;
      case 'Resources':
        if (!this.showResources) this.showResources = true;
        break;
      case 'Checklists':
        if (!this.showChecklists) this.showChecklists = true;
        break;
      case 'Attachments':
        if (!this.showAttachments) this.showAttachments = true;
        break;
    }
  }

  onCustCollapseChange(event: any) {
    this.custClosed.set(event);
  }

  onWODCollapseChange(event: any) {
    this.woDetailsClosed.set(event);
  }

  onEquipCollapseChange(event: any) {
    this.equipClosed.set(event);
  }

  closePopover() {
    this.popovers.forEach((pop) => {
      pop.hide();
    });
  }

  onSelectAddWorkers(workers) {
    this.selectedWorkersToAdd = workers;
  }

  async addWorkersToWorkOrder() {
    if (!this.selectedWorkersToAdd) {
      return;
    }

    const workerIds = this.selectedWorkersToAdd.map(({ id }) => id);
    this.closePopover();

    await this.workOrderService
      .addWorkersToWorkOrder(this.workorder.id, workerIds)
      .toPromise();
    this.workerList.refreshGrid();
  }

  async removeWorkersFromWorkOrder() {
    await this.workOrderService
      .removeWorkersFromWorkOrder(
        this.workorder.id,
        this.selectedWorkersToDelete,
      )
      .toPromise();
    this.selectedWorkersToDelete = null;
    this.workerList.refreshGrid();
    this.workerList.deselectAll();
  }

  onSelectRemoveWorkersFromWorkOrder(workers) {
    this.selectedWorkersToDelete = workers?.map((svw) => svw.workerId);
  }

  getWorkOrderStatuses() {
    this.workOrderService.getWorkOrderStatuses().subscribe(({ data }) => {
      this.statusList = (<any>data).getWorkOrderStatuses;
    });
  }

  onStatusChange(status) {
    this.setLoadingState('status', true);
    this.selectedStatus = status;
    const dto = {
      id: this.workorder.id,
      status: this.selectedStatus,
    };
    this.subs.add(
      this.workOrderService.updateWorkOrderStatus(dto).subscribe({
        next: () => {
          this.setLoadingState('status', false);
        },
        error: () => {
          this.setLoadingState('status', false);
        },
      }),
    );
  }

  updateCustomerPO(poNumber) {
    this.setLoadingState('poNumber', true);
    const dto = {
      id: this.workorder.id,
      poNumber,
    };
    this.subs.add(
      this.workOrderService.updateWorkOrderPO(dto).subscribe({
        next: () => {
          this.setLoadingState('poNumber', false);
        },
        error: () => {
          this.setLoadingState('poNumber', false);
        },
      }),
    );
  }
  // For the future
  // updateField(fieldName: string, dto: any) {
  //   this.setLoadingState(fieldName, true);
  //   this.subs.add(
  //     this.workOrderService.updateField(dto).subscribe({
  //       next: () => {
  //         this.setLoadingState(fieldName, false);
  //       },
  //       error: () => {
  //         this.setLoadingState(fieldName, false);
  //       }
  //     })
  //   );
  // }

  setLoadingState(fieldName: string, state: boolean) {
    this.loadingStates[fieldName] = state;
  }

  isLoading(fieldName: string): boolean {
    return this.loadingStates[fieldName];
  }

  selectServiceVisit(serviceVisit) {
    if (!serviceVisit) {
      return;
    }
    this.selectedServiceVisit = serviceVisit[0];
  }
}
