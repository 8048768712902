import { gql } from 'apollo-angular';

export const MUTATION_UpdateTimeSheet = gql`
  mutation updateTimeSheet($dto: DtoUpdateTimeSheet!) {
    updateTimeSheet(dto: $dto) {
      id
      startDateTime
      endDateTime
      activityCodeId
      activityCode {
        id
        code
        fabColor
        fabIcon
      }
    }
  }
`;
