import { gql } from "apollo-angular";

export const Mutation_DeleteWorkersFromWorkOrder = gql`
  mutation deleteWorkersFromWorkOrder($workOrderId: String!, $workerIds: [String!]!) {
    deleteWorkersFromWorkOrder(workOrderId: $workOrderId, workerIds: $workerIds) {
      id
      updatedAt
      updatedBy
      workOrderWorkers{
        id
        workerId
        workOrderId
      }
    }
  }
`;