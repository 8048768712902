import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import ApexCharts from 'apexcharts';
import moment from 'moment';

@Component({
  selector: 'stack-mono-service-visit-timeline',
  templateUrl: './service-visit-timeline.component.html',
  styleUrls: ['./service-visit-timeline.component.scss']
})
export class ServiceVisitTimelineComponent implements OnInit, OnChanges, OnDestroy {
  _serviceVisit;
    @Input() set serviceVisit(value) {
        this._serviceVisit = value;
        this.timeSheets = value?.timeSheets;
        this.serviceVisitWorkers = value?.serviceVisitWorkers;
  
        this.updateTimelineChart();
    }
    get serviceVisit() {
        return this._serviceVisit;
    }
  @Output() timesheetSelected = new EventEmitter<any>();
  
   timeSheets;
   serviceVisitWorkers;
  private timelineChart: ApexCharts;
  private chartOptions: any;
  private chartElement: HTMLElement;

  constructor(private readonly elementRef: ElementRef) {}

  ngOnInit(): void {
    this.chartElement = this.elementRef.nativeElement.querySelector('#service-visit-timeline-chart');
    this.initializeTimelineChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update chart when inputs change
    if ((changes.serviceVisitWorkers || changes.timeSheets) && this.timelineChart) {
      this.updateTimelineChart();
    }
  }

  ngOnDestroy(): void {
    // Clean up ApexCharts instance
    if (this.timelineChart) {
      this.timelineChart.destroy();
    }
  }

  // Format worker and timesheet data for the chart
  private formatDataForChart() {
    if (!this.timeSheets || !this.timeSheets.length) {
      return [];
    }
    
    // Structure timesheets by worker for processing
    const timesheetsByWorker = new Map();
    
    // Extract distinct workers from timesheets
    const distinctWorkerIds = [...new Set(this.timeSheets.map(ts => ts.workerId))];
    
    // Create a lookup for mapping worker IDs to names for consistency
    const workerNameMap = new Map();
    
    // For each worker, gather their information and timesheets
    distinctWorkerIds.forEach((workerId, index) => {
      // Find the corresponding worker from serviceVisitWorkers if available
      const serviceVisitWorker = this.serviceVisitWorkers?.find(svw => svw.worker?.id === workerId);
      
      // Get worker name, trying multiple sources
      let workerName;
      if (serviceVisitWorker) {
        workerName = serviceVisitWorker.worker?.displayName || serviceVisitWorker.worker?.workerNum;
      }
      
      // If we couldn't get the name from serviceVisitWorker, try to get it from the first timesheet
      if (!workerName) {
        const firstTimesheet = this.timeSheets.find(ts => ts.workerId === workerId);
        workerName = firstTimesheet?.worker?.displayName || firstTimesheet?.worker?.workerNum;
      }
      
      // Fallback name if no name found
      if (!workerName) {
        workerName = `Worker ${index + 1}`;
      }
      
      // Store the name for consistent usage
      workerNameMap.set(workerId, workerName);
      
      // Get all timesheets for this worker
      const workerTimeSheets = this.timeSheets.filter(ts => ts.workerId === workerId);
      
      // Add worker and their timesheets to the mapping
      timesheetsByWorker.set(workerId, {
        workerId,
        workerName,
        serviceVisitWorker,
        timesheets: workerTimeSheets
      });
    });
    
    // Process each worker's data and handle overlaps
    const chartData = this.processWorkersWithOverlapHandling(timesheetsByWorker);
    
    // Add annotations for important service visit worker dates
    const annotations = this.createAnnotationsFromServiceVisitWorkers();
    
    return {
      chartData,
      annotations
    };
  }
  
  // Create annotations from important service visit worker dates
  private createAnnotationsFromServiceVisitWorkers() {
    if (!this.serviceVisitWorkers || !this.serviceVisitWorkers.length) {
      return [];
    }
    
    const annotations = [];
    
    // Important date fields to annotate
    const importantDateFields = [
      { field: 'scheduledStartDateTime', label: 'Scheduled Start', color: '#FF9800' }, // Orange
      { field: 'scheduledEndDateTime', label: 'Scheduled End', color: '#FF5722' }, // Deep Orange
      { field: 'onSiteStartDateTime', label: 'On-Site Start', color: '#4CAF50' }, // Green
      { field: 'reviewDateTime', label: 'Review', position:'top', color: '#9C27B0' }, // Purple
      { field: 'travelStartDateTime', label: 'Travel Start', color: '#2196F3' }, // Blue
      {field: 'onSiteEndDateTime', label: 'On-Site End', color: '#FF5722' }, // Deep Orange
    ];
    
    // Store time positions to detect and avoid overlaps
    const timePositions = [];
    
    // Process each worker's important dates
    this.serviceVisitWorkers.forEach(worker => {
      if (!worker) return;
      
      // Add annotations for each important date field
      importantDateFields.forEach(({ field, label, color,position }) => {
        if (worker[field]) {
          const dateTime = new Date(worker[field]).getTime();
          
          // Calculate position to avoid label overlap
          
          annotations.push({
            x: dateTime,
            strokeDashArray: 0,
            borderColor: color,
            label: {
             // orientation: 'horizontal',
              
              borderColor: color,
              style: {
                color: '#fff',
                background: color,
                fontSize: '10px'  // Slightly smaller font for annotations
              },
              text: label,
              position: position ?? 'bottom',
            }
          });
          
          // Track this time position to avoid future overlaps
          timePositions.push({
            time: dateTime,
          });
        }
      });
      
      // Add travel distance information if available
      if (worker.estTravelDistance && worker.travelStartDateTime) {
        const travelDateTime = new Date(worker.travelStartDateTime).getTime();
        
        // Calculate position to avoid label overlap
        
        annotations.push({
          x: travelDateTime,
          strokeDashArray: 0,
          borderColor: '#795548', // Brown
          label: {
            borderColor: '#795548',
            style: {
              color: '#fff',
              background: '#795548',
              fontSize: '10px'
            },
            text: `Start Travel (${worker.estTravelDistance} mi)`,
            position: 'bottom',
          }
        });
        
      }
    });
    
    return annotations;
  }
  

  
  // Process worker data and handle timesheet overlaps
  private processWorkersWithOverlapHandling(timesheetsByWorker) {
    const chartData = [];
    const MAX_ROWS_PER_WORKER = 3; // Maximum rows per worker to handle overlaps
    
    // Process each worker's data
    timesheetsByWorker.forEach((workerData) => {
      const { workerId, workerName, serviceVisitWorker, timesheets } = workerData;
      
      // Add worker onsite time if available
      if (serviceVisitWorker && serviceVisitWorker.onSiteStartDateTime && serviceVisitWorker.onSiteEndDateTime) {
        const start = new Date(serviceVisitWorker.onSiteStartDateTime).getTime();
        const end = new Date(serviceVisitWorker.onSiteEndDateTime).getTime();
        
        chartData.push({
          x: workerName,
          y: [start, end],
          fillColor: '#607D8B', // Grey-blue for worker onsite time
          strokeColor: '#455A64',
          type: 'workerOnsite',
          workerId: workerId,
          opacity: 0.3 // Make the worker onsite time more transparent
        });
      }
      
      // Sort timesheets by start time
      const sortedTimesheets = [...timesheets].sort((a, b) =>
        new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime()
      );
      
      // Initialize rows for this worker to track timesheet placement
      const rows = Array(MAX_ROWS_PER_WORKER).fill(null).map(() => []);
      
      // Calculate overlap threshold (15 minutes in milliseconds)
      const OVERLAP_THRESHOLD = 15 * 60 * 1000;
      
      // Assign each timesheet to a row
      sortedTimesheets.forEach((timesheet, originalIndex) => {
        const start = new Date(timesheet.startDateTime).getTime();
        const end = new Date(timesheet.endDateTime || new Date()).getTime();
        
        // Find first row where this timesheet can fit without significant overlap
        let rowIndex = 0;
        let assigned = false;
        
        // Try to find a row where this timesheet doesn't overlap by more than the threshold
        for (rowIndex = 0; rowIndex < MAX_ROWS_PER_WORKER; rowIndex++) {
          const rowTimesheets = rows[rowIndex];
          let canFit = true;
          
          for (const existingTs of rowTimesheets) {
            const existingStart = existingTs.y[0];
            const existingEnd = existingTs.y[1];
            
            // Check if overlap exceeds threshold
            const overlapStart = Math.max(start, existingStart);
            const overlapEnd = Math.min(end, existingEnd);
            const overlapDuration = Math.max(0, overlapEnd - overlapStart);
            
            if (overlapDuration > OVERLAP_THRESHOLD) {
              canFit = false;
              break;
            }
          }
          
          if (canFit) {
            assigned = true;
            break;
          }
        }
        
        // If no row has space without significant overlap, use the last row
        if (!assigned) {
          rowIndex = MAX_ROWS_PER_WORKER - 1;
        }
        
        // Create the data point with adjusted label for overlapping rows
        const adjustedLabel = rowIndex === 0 ? workerName : `${workerName} (${rowIndex + 1})`;
        
        const timesheetDataPoint = {
          x: adjustedLabel,
          y: [start, end],
          fillColor: timesheet.activityCode?.fabColor || '#4CAF50', // Green if no color specified
          strokeColor: timesheet.activityCode?.fabColor || '#388E3C',
          type: 'timesheet',
          timesheetId: timesheet.id,
          workerId: workerId,
          activityCode: timesheet.activityCode?.code,
          timesheetIndex: originalIndex // Store original index for selection
        };
        
        // Add to chart data and track in the assigned row
        chartData.push(timesheetDataPoint);
        rows[rowIndex].push(timesheetDataPoint);
      });
    });
    
    return chartData;
  }

  // Initialize the ApexCharts timeline
  private initializeTimelineChart() {
    // Set default timeframe if no data
    const today = new Date();
    const minTime = new Date(today).setHours(8, 0, 0, 0); // 8am
    const maxTime = new Date(today).setHours(17, 0, 0, 0); // 5pm
    
    // Create initial empty annotations array
    const annotations = {
      xaxis: []
    };
    
    this.chartOptions = {
      series: [
        {
          name: 'Timeline',
          data: []
        }
      ],
      chart: {
        zoom: {
          enabled: false,
          allowMouseWheelZoom: false,
        },
        height: 180, // Smaller height for timeline style
        type: 'rangeBar',
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // Get the selected data point
            if (config.dataPointIndex !== -1 && config.seriesIndex !== -1) {
              const selectedPoint = config.w.config.series[config.seriesIndex].data[config.dataPointIndex];
              
              // Only emit for timesheet type points, not worker onsite time
              if (selectedPoint && selectedPoint.type === 'timesheet' &&
                  this.timeSheets && this.timeSheets.length > 0) {
                
                // Find the timesheet by ID
                const timesheet = this.timeSheets.find(ts => ts.id === selectedPoint.timesheetId);
                
                if (timesheet) {
                  this.timesheetSelected.emit(timesheet);
                }
              }
            }
          },
        },
      },
      annotations: annotations,
      grid: {
        show: false, // Hide grid for timeline style
        padding: {
          left: 15,
          right: 30,
          top: 2,
          bottom: 2
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%', // Taller bars for better visibility
          distributed: false,
          rangeBarGroupRows: false
        }
      },
      states: {
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'darken',
            value: 0.35
          }
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.15
          }
        }
      },
      xaxis: {
        type: 'datetime',
        min: minTime,
        max: maxTime,
        labels: {
          show: true,
          style: {
            fontSize: '11px'
          },
          formatter: function(value) {
            return moment(value).format('h:mm A');
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: '14px',
            fontWeight: 700 // Bold worker names for better visibility
          }
        }
      },
      stroke: {
        width: 1
      },
      fill: {
        type: 'solid',
        opacity: 0.6 // Reduced opacity for better aesthetics
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: true,
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const data = w.config.series[seriesIndex].data[dataPointIndex];
          const startTime = moment(data.y[0]).format('h:mm A');
          const endTime = moment(data.y[1]).format('h:mm A');
          const duration = moment.duration(data.y[1] - data.y[0]).asHours().toFixed(2);
          
          const title = data.type === 'workerOnsite' ? 'Worker Onsite Time' : data?.activityCode;
          const clickInfo = data.type === 'timesheet' ? '<div>Click to select timesheet</div>' : '';
          return `<div class="apexcharts-tooltip-title" style="font-size: 12px; padding: 4px 8px;">
                    <span>${title}</span>
                    <div>${startTime} - ${endTime} (${duration} hrs)</div>
                  </div>`;
        }
      }
    };
    
    // Initialize the chart
    if (this.timelineChart) {
      this.timelineChart.destroy();
    }
    
    this.timelineChart = new ApexCharts(
      document.querySelector("#service-visit-timeline-chart"),
      this.chartOptions
    );
    
    this.timelineChart.render();
    
    // Update with actual data
    this.updateTimelineChart();
  }

  // Update the timeline chart with new data
  private updateTimelineChart() {
    if (!this.timelineChart) {
      return;
    }
    // Format the data for the chart
    const formattedData = this.formatDataForChart();
    
    // Handle empty response (no timesheets)
    if (!formattedData || Array.isArray(formattedData) && formattedData.length === 0) {
      const today = new Date();
      const defaultTimeRange = {
        min: new Date(today).setHours(8, 0, 0, 0), // 8am
        max: new Date(today).setHours(17, 0, 0, 0)  // 5pm
      };
      
      this.timelineChart.updateOptions({
        xaxis: {
          min: defaultTimeRange.min,
          max: defaultTimeRange.max
        }
      });
      
      this.timelineChart.updateSeries([{
        name: 'Timeline',
        data: []
      }]);
      
      return;
    }
    
    // Extract chart data and annotations from formatted response
    let chartData = [];
    let annotations = [];
    if (!Array.isArray(formattedData)) {
      ({ chartData, annotations } = formattedData);
    }
    
    // Find the range of times to display (from earliest to latest time)
    // Using objects to hold mutable values and avoid ESLint errors
    const timeRange = {
      min: null,
      max: null
    };
    
    // Find the earliest start time and latest end time from all data points
    if (chartData && chartData.length > 0) {
      chartData.forEach(data => {
        const startTime = data.y[0];
        const endTime = data.y[1];
        
        if (timeRange.min === null || startTime < timeRange.min) {
          timeRange.min = startTime;
        }
        
        if (timeRange.max === null || endTime > timeRange.max) {
          timeRange.max = endTime;
        }
      });
      
      // Add some buffer time on both ends (30 minutes)
      if (timeRange.min !== null && timeRange.max !== null) {
        timeRange.min = new Date(timeRange.min).setMinutes(new Date(timeRange.min).getMinutes() - 30);
        timeRange.max = new Date(timeRange.max).setMinutes(new Date(timeRange.max).getMinutes() + 30);
      }
    } else {
      // Default to business hours if no data
      const today = new Date();
      timeRange.min = new Date(today).setHours(8, 0, 0, 0); // 8am
      timeRange.max = new Date(today).setHours(17, 0, 0, 0); // 5pm
    }
    
    // Check for any annotation dates that might be outside our current time range
    if (annotations && annotations.length > 0) {
      annotations.forEach(annotation => {
        const annotationTime = annotation.x;
        
        if (timeRange.min === null || annotationTime < timeRange.min) {
          timeRange.min = annotationTime;
        }
        
        if (timeRange.max === null || annotationTime > timeRange.max) {
          timeRange.max = annotationTime;
        }
      });
    }
    
    // Update chart options with annotations and x-axis range
    this.timelineChart.updateOptions({
      xaxis: {
        type: 'datetime',
        min: timeRange.min,
        max: timeRange.max,
        labels: {
          show: true,
          style: {
            fontSize: '12px'
          },
          formatter: function(value) {
            return moment(value).format('h:mm A');
          },
          datetimeUTC: false
        },
        tickAmount: 6
      },
      annotations: {
        xaxis: annotations
      }
    });
    
    // Update chart data
    this.timelineChart.updateSeries([{
      name: 'Timeline',
      data: chartData
    }]);
  }
}