import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '../../@core/services/window.service';
import { WorkOrderDetailComponent } from './detail/workorderdetail.component';

@Component({
  selector: 'ngx-workorder',
  templateUrl: './workorder.component.html',
  styleUrls: ['./workorder.component.scss'],
})
export class WorkOrderComponent {
  constructor(
    private readonly router: Router,
    private readonly windowService: WindowService,
  ) {}

  onClick(workOrder) {
    const { id } = workOrder;
    if (!id) return;

    this.windowService.openWindow(WorkOrderDetailComponent, {
      objectId: id,
      context: { id },
    });

    // this.router.navigate(['/pages/workorders/', id], {
    //   queryParams: { tab: 'Details' },
    //   queryParamsHandling: 'merge',
    // });
  }
}
