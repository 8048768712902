import gql from 'graphql-tag';

export const Query_GetReleases = gql`
  query GetReleases($search: DtoPaginatedFilter!) {
    getReleases(search: $search) {
      edges {
        node {
          id
          version
          minVersion
          releaseDate
          platform
          updateType
          patchNotes
          testFlightUrl
          appStoreUrl
          playStoreUrl
          active
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export const TOGGLE_RELEASE_ACTIVE = gql`
  mutation ToggleReleaseActive($id: String!, $active: Boolean!) {
    toggleReleaseActive(id: $id, active: $active) {
      id
      active
    }
  }
`;

export const ADD_RELEASE = gql`
  mutation AddRelease($data: DtoAddRelease!) {
    addRelease(data: $data) {
      id
      version
      minVersion
      releaseDate
      platform
      updateType
      patchNotes
      testFlightUrl
      appStoreUrl
      playStoreUrl
      active
    }
  }
`;

export const UPDATE_RELEASE = gql`
  mutation UpdateRelease($data: DtoUpdateRelease!) {
    updateRelease(data: $data) {
      id
      version
      minVersion
      releaseDate
      platform
      updateType
      patchNotes
      testFlightUrl
      appStoreUrl
      playStoreUrl
      active
    }
  }
`;
