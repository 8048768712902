import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MUTATION_ADDLOCATION } from '../graphql/mutations/locations/addlocation.mutation';
import { QUERY_GETLOCATIONS } from '../graphql/queries/locations/getlocations.query';
import { QUERY_GETTEAMSBYIDS } from '../graphql/queries/teams/getTeamsByIds.query';
import { Query_GetWorkersByTeamBranch } from '../graphql/queries/workers/getWorkersByTeamBranch';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(private apollo: Apollo) {}

  // addLocation(data: any) {
  //   return this.apollo.mutate({
  //     mutation: MUTATION_ADDLOCATION,
  //     variables: { data },
  //     refetchQueries: [
  //       {
  //         query: QUERY_GETLOCATIONS,
  //       },
  //     ],
  //   });
  // }

  getTeamsByIds(teamIds: string, branchNum: string) {
    return this.apollo.watchQuery({
      query: QUERY_GETTEAMSBYIDS,
      variables: { teamIds, branchNum },
    }).valueChanges;
  }

  getWorkersByTeamBranch(teams: string, branch: string) {
    return this.apollo.query({
      query: Query_GetWorkersByTeamBranch,
      variables: { teams, branch },
    });
  }
}
