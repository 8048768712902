import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import 'ag-grid-enterprise';
import { SubSink } from 'subsink';
import { GridApi } from 'ag-grid-community';
import { IPaginatedFilter } from '../../../@core/interfaces/IPaginatedFilter';
import { GridUtilService } from '../../../@core/services/gridUtil.service';
import { DynamicListViewService } from './dynamiclistview.service';
import moment from 'moment';
import { from, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { QueueService } from '../grid-queues/services/queue.service';
import { DtoAddQueue, QueueViewType } from '../grid-queues/dto/dtoAddQueue.dto';
import { NbDialogService } from '@nebular/theme';
import { JsonEditorOptions } from '@maaxgr/ang-jsoneditor';
import { Apollo } from 'apollo-angular';
import { DtoGetQueuesByObject } from '../grid-queues/dto/DtoGetQueuesByObject';
import { DraggableCellRenderer } from '../cellrenderers/CellRenderShowInScheduler.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { GridQueueComponent } from '../grid-queues/grid-queue.component';
import { DtoGetObjectColumnLayout } from './dto/dtoGetObjectColumnLayout.dto';
import { ButtonRendererComponent } from './cellRenderers/buttonCellRenderer';
import { set } from 'date-fns';

@Component({
  selector: 'next-object-list',
  templateUrl: './dynamiclistview.component.html',
  styleUrls: ['./dynamiclistview.component.scss'],
})
export class DynamicListViewComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  sub = new SubSink();
  @ViewChild('dynamicPopover') popoverPortalTmplRef;
  @ViewChild('gridQueue', { static: true }) gridQueue: GridQueueComponent;
  @Input() object: string;
  @Input() hideToolBar: boolean;
  @Input() hideQueues: boolean;
  @Input() newButtonTitle: string;
  @Input() hideControlBar = false;
  @Input() newButtonPopover: TemplateRef<any>;
  @Input() showQuickSearch = false;
  @Input() quickSearchColumns = [];
  @Input() viewType?: QueueViewType = QueueViewType.Default;
  @Input() contextMenu: any;
  @Input() masterDetail: false;
  @Input() showCheckbox: false;
  @Input() displayFilters;
  @Input() page = '';
  @Input() showObjectPicker?: boolean | string[] = false;
  @Input() selectFirstRow = false;
  @Input() rowSelection = 'multiple' || 'single';
  @Input() selectedIds: string[] = [];
  @Input() queues: any = [];
  @Output() quickSearchValue = new EventEmitter();
  @Output() onDoubleClick = new EventEmitter();
  @Output() onNewObject = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  @Output() onCheckboxSelect = new EventEmitter();
  @Output() buttonAction = new EventEmitter();
  @Output() visibleRows = new EventEmitter();
  @Output() totalRecordCount = new EventEmitter();
  @Output() allRecords = new EventEmitter();
  @Output() gridready = new EventEmitter();

  @Input() set height(height: number) {
    this._height = height;
    this.visibleRowsChange.next({ top: this.scrollPosition });
  }
  get height() {
    return this._height;
  }

  @Input() set filters(filters: any) {
    const oldFilters = this._filters;
    this._filters = filters;

    if (oldFilters !== filters) {
      this.filtersChanged = true;
      this.gridApi?.onFilterChanged();
      this.updateAllQueueCounts();
    }
  }
  get filters() {
    return this._filters;
  }

  @Input() set orFilters(filters: any) {
    this._filtersOR = filters;
    this.gridApi?.onFilterChanged();
  }
  get orFilters() {
    return this._filtersOR;
  }

  @Input() set data(data) {
    this._data = data?.map((item) => ({
      ...item,
    }));

    if (this.gridOptions && this.gridOptions.api) {
      this.adhocFilter = {
        filter: null,
        recordCount: data.length,
        name: '',
        type: 'Private',
      };
      this.gridApi.setRowData(data);
    }
  }
  get data() {
    return this._data;
  }
  visibleRowsChange = new EventEmitter();
  _height = 700;
  _data: any;
  _filters: any;
  _filtersOR: any;

  selectedRow = null;
  buttons: any = [];
  detailCellRendererParams: any;
  lastRowId: string;
  isLoading = false;
  gridOptions: any = null;
  gridApi: GridApi;
  columnDefs = [];

  adhocFilter: any;
  queueQuery;
  firstLoad = true;
  editorOptions: JsonEditorOptions;
  columnModel: any;
  gridQuery: any;
  rowHeight = 48;
  gridBodyHeight;
  scrollPosition = 0;
  visibleRowNodes = [];
  activeSubObs;
  activeSubQueueObs;
  rndId = '';
  isGridReady = false;
  private filtersChanged = false;
  externalFilters = [];

  constructor(
    private readonly dynamicListViewService: DynamicListViewService,
    private readonly gridUtilService: GridUtilService,
    private readonly queueService: QueueService,
    private readonly dialogService: NbDialogService,
    private readonly elementRef: ElementRef,
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'text'; // set all allowed modes
    this.rndId = Math.floor(Math.random() * 9000).toString();
  }

  ngOnInit() {
    this.setupGridOptions();
    this.sub.sink = this.quickSearchValue
      .pipe(debounceTime(500))
      .subscribe((val) => {
        this.quickSearch(val);
      });

    if (!this.queues?.length) {
      this.sub.sink = this.queueService.selectedViewGroup$.subscribe(() => {
        this.queues = [];
        this.getQueues();
      });
    }
  }

  ngAfterViewInit() {
    if (this.showQuickSearch) {
      document.getElementById('quickSearch').focus();
    }
  }

  emitButtonAction(e) {
    this.buttonAction.emit(e);
  }

  onBtnClick1(e) {
    this.onAdd.emit(e.rowData);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.activeSubObs?.unsubscribe();
    this.gridOptions = null;
  }

  async onObjectChange(e) {
    this.object = e;
    await this.getColumnDefs();
  }

  private handleModeUpdateAndScrollEvent() {
    const headerElement =
      this.elementRef.nativeElement.querySelectorAll('.ag-header');
    const agGrid =
      this.elementRef.nativeElement.querySelectorAll('ag-grid-angular');

    this.gridOptions.onBodyScroll = (event) => {
      if (event.direction === 'vertical') {
        this.scrollPosition = event.top;
        this.visibleRowsChange.next(event);
      }
    };
    this.gridOptions.onModelUpdated = (event) => {
      this.visibleRowsChange.next({ top: this.scrollPosition });
    };

    this.sub.sink = this.visibleRowsChange
      .pipe(debounceTime(10))
      .subscribe((v) => {
        this.gridBodyHeight =
          agGrid[0].offsetHeight - headerElement[0].offsetHeight;
        this.rowHeight = this.gridApi.getDisplayedRowAtIndex(0)
          ? this.gridApi.getDisplayedRowAtIndex(0).rowHeight
          : this.rowHeight;
        const topIndex = v.top / this.rowHeight;
        const bottomIndex = (v.top + this.gridBodyHeight) / this.rowHeight + 1;
        this.visibleRowNodes = this.gridApi
          .getRenderedNodes()
          .filter(
            (node) => node.rowIndex >= topIndex && node.rowIndex <= bottomIndex,
          );
        this.visibleRows.emit(
          this.visibleRowNodes
            .filter((x) => x !== undefined)
            .map((node) => node.data),
        );
      });
  }

  setupFirstRowSelector() {
    if (this.filtersChanged || this.firstLoad) {
      setTimeout(() => {
        if (this.selectFirstRow) {
          let stop = false;
          this.gridOptions.api.forEachNode((node) => {
            if (!stop) {
              node.setSelected(true);
              if (node.data) {
                stop = true;
              }
            }
          });
        }
      }, 100);
      this.filtersChanged = false;
      this.firstLoad = false;
    }

    if (this.selectedIds.length > 0) {
      this.gridOptions.api.forEachNode((node) => {
        if (this.selectedIds.includes(node.data.id)) {
          node.setSelected(true);
        }
      });
    }
  }

  onQuickSearchChange(val) {
    this.quickSearchValue.emit(val);
  }

  quickSearch(val) {
    const filter = this.quickSearchColumns.map((col) => {
      return {
        column: col,
        value: `*${val}*`,
      };
    });
    this.orFilters = filter;
  }

  initAbstractFields() {
    this.columnDefs.forEach((col) => {
      if (!col.format) return;
      const format = col.format.includes(':')
        ? col.format.split(':')[0]
        : col.format;
      const params = col.format.substring(format.length + 1);
      switch (format?.toUpperCase()) {
        case 'CHILDRECORD':
          this.addFiledChildRecordToData(col.field, params);
          col.field = params.split(':')[1].replace(' ', '');
      }
    });
    this.gridApi.setDatasource(this.gridDS());
  }

  addFiledChildRecordToData(object, params) {
    const split = params.split(':');
    const field = split[0];
    const value = split[1];

    this._data.map((item) => {
      const childArray = item[object];

      if (childArray && childArray.length > 0) {
        const child = childArray.filter((child) => {
          return child[field] === value;
        });

        if (child.length > 0) {
          item[value.replace(' ', '')] =
            `${child[0]?.workerNum} | ${child[0]?.vendorItemNum}`;
        }
      }
    });
  }

  initCustomerCellRenders() {
    if (this.viewType === QueueViewType.Scheduler) {
      (<any>this.columnDefs[0]).cellRenderer = 'draggableCellRenderer';
    }

    this.columnDefs.forEach((col) => {
      if (!col.format) return;
      const format = col.format.includes(':')
        ? col.format.split(':')[0]
        : col.format;
      const params = col.format.substring(format.length + 1);
      switch (format?.toUpperCase()) {
        case 'JSON':
          col.valueFormatter = this.jsonRenderer(params);
          break;
        case 'COUNT':
          col.valueFormatter = this.cellRendererCount(params);
          break;
        case 'DATE':
          col.valueFormatter = this.cellRendererDate(params);
          break;
        case 'ROUND':
          col.valueFormatter = this.cellRendererRound(params);
          break;
        case 'CURRENCY':
          col.valueFormatter = this.cellRendererCurrency();
          break;
        case 'BUTTON':
          col.cellRenderer = 'buttonRenderer';
          col.cellRendererParams = {
            onClick: this.onBtnClick1.bind(this),
            label: params,
          };
          break;
        case 'CHILDRECORD':
          col.valueFormatter = this.cellRendererChildRecord();
          break;

        case 'FORMAT':
          col.valueFormatter = this.cellRendererFormat(params);
          break;
        case 'MATH':
          col.valueFormatter = this.cellRendererMath(params);
        // case 'REPLACE':
        //   col.valueFormatter = this.cellRendererReplace(params);
        //   break;
        // col.field = params.split(":")[1];

        // col.valueFormatter = this.cellRendererChildRecord(params);
      }
    });
  }

  private cellRendererCount(params) {
    return (data) => {
      return data?.value?.length;
    };
  }
  private jsonRenderer(params) {
    return (data) => {
      if (!data.value) {
        return '';
      }
      return JSON.stringify(data.value);
    };
  }

  private cellRendererReplace(params) {
    const actions = params.split(':');
    return (data) => {
      const value = data?.value?.replace(actions[0] || '', actions[1] || '');
      return value;
    };
  }

  private cellRendererChildRecord() {
    return (node) => {
      try {
        const record = node?.data;
        const fieldPath = node?.colDef?.field;

        if (!record || !fieldPath) {
          return '';
        }

        const fields = fieldPath.split('.');
        const firstArray = record[fields[0]];

        if (!Array.isArray(firstArray)) {
          return '';
        }

        const getNestedValue = (item, fieldPath) => {
          return fieldPath.reduce((obj, field) => obj?.[field], item);
        };

        const processArray = (array, remainingFields) => {
          return array.reduce((values, item) => {
            if (Array.isArray(item[remainingFields[0]])) {
              const nestedValues = processArray(
                item[remainingFields[0]],
                remainingFields.slice(1),
              );
              return [...values, ...nestedValues];
            }

            const value = getNestedValue(item, remainingFields);
            return value ? [...values, value] : values;
          }, []);
        };

        // Get all values from the nested array structure
        const values = processArray(firstArray, fields.slice(1));
        // Use a Set to remove duplicate values while preserving the order
        const uniqueValues = [...new Set(values)];
        return uniqueValues.join(', ');
      } catch (e) {
        console.error('Cell renderer error:', e);
        return '';
      }
    };
  }

  private cellRendererDate(format) {
    return (data) => {
      const datepipe: DatePipe = new DatePipe('en-US');
      return datepipe.transform(data.value, format);
    };
  }

  private cellRendererCurrency() {
    return (data) => {
      const currencyPipe: CurrencyPipe = new CurrencyPipe('en-US');
      return currencyPipe.transform(data.value);
    };
  }

  private cellRendererRound(decimal: number) {
    return (data) => {
      return data?.value?.toLocaleString('en-US', {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    };
  }

  private cellRendererFormat(params) {
    const format = params.substring(params.indexOf(':') + 1);

    return (data) => {
      return format.replace('{val}', data.value);
    };
  }

  private cellRendererMath(params) {
    //const format = params.substring(params.indexOf(':') + 1);
    const actions = params.split(':');
    switch (actions[0].toUpperCase()) {
      case 'DIVIDE':
        return (data) => {
          return (data.value / actions[1]).toPrecision(2);
        };
    }

    return null;
  }

  openDialog(dialog: TemplateRef<any>, context?: any) {
    this.dialogService.open(dialog);
  }

  handleResetObjectColumnLayout(object: string) {
    if (object) {
      const dto = {
        object,
      };
      const res = this.dynamicListViewService.resetObjectColumnLayout(dto);
      res.then(() => {
        this.getColumnDefs();
        this.refreshGrid();
      });
    }
  }

  getSelectedRows() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  }

  getQueues() {
    if (this.hideQueues) {
      return;
    }
    if (this.queues.length > 0) {
      this.updateAllQueueCounts();
      setTimeout(() => {
        if (this.queues.length > 0) {
          this.filterGrid(this.queues[0].filter, false);
        }
        if (!this.data) {
          this.gridApi.setDatasource(this.gridDS());
        }
      }, 250);
      return;
    } else {
      const dto: DtoGetQueuesByObject = {
        object: this.object,
        viewType: this.viewType,
        page: this.page,
      };
      const { query, sub } = this.queueService.getQueuesByObjectType(dto);

      this.queueQuery = query;
      this.sub.sink = sub.subscribe((queues) => {
        const sortedQueues = this.loadQueueSortFromLocalStorage(queues);
        this.queues = sortedQueues;
        this.updateAllQueueCounts();
        if (this.gridApi) {
          if (this.firstLoad) {
            this.firstLoad = false;

            setTimeout(() => {
              if (this.queues.length > 0) {
                this.filterGrid(this.queues[0].filter, false);
              }
              if (!this.data) {
                this.gridApi.setDatasource(this.gridDS());
              }
            }, 250);
          }
        }
      });
    }
  }

  loadQueueSortFromLocalStorage(queues) {
    const sort = localStorage.getItem(`queueSort-${this.object}`);
    if (sort) {
      let sortList: string[] = JSON.parse(sort);
      sortList = sortList.filter((id) => queues.find((q) => q.id === id));
      return queues.sort((a, b) => {
        const aL = sortList.indexOf(a.id) === -1 ? 999 : sortList.indexOf(a.id);
        const bL = sortList.indexOf(b.id) === -1 ? 999 : sortList.indexOf(b.id);
        return aL - bL;
      });
    } else {
      return queues;
    }
  }

  async getColumnDefs() {
    this.isLoading = true;

    const dto: DtoGetObjectColumnLayout = {
      object: this.object,
      page: this.page,
    };
    const { data } = await this.dynamicListViewService
      .getObjectColumnLayout(dto)
      .toPromise();

    this.columnModel = data['getObjectColumnLayoutByObjectType'];

    const cols = [...data['getObjectColumnLayoutByObjectType'].columns];
    this.buttons = data['getObjectColumnLayoutByObjectType'].buttons;
    this.columnDefs = cols.map((col) => {
      return { ...col };
    });
    if (this.showCheckbox) {
      this.columnDefs[0].checkboxSelection = true;
      this.columnDefs[0].showDisabledCheckboxes = true;
    }

    this.isLoading = false;
  }

  f;
  filterGrid(filter, triggerChange = true) {
    // First, clear any existing external filters
    this.externalFilters = [];

    const filterModel = {};
    if (filter?.length > 0) {
      filter.forEach((filter: any) => {
        // Check if the column exists in the grid
        const columnExists = this.columnDefs.some(
          (col) => col.field === filter.column,
        );

        if (columnExists) {
          // If column exists, add it to the filter model for AG Grid
          filterModel[filter.column] = {
            filter: filter.value,
            filterType: 'Text',
            type: 'contains',
          };
        } else {
          // If column doesn't exist in grid, store it as an external filter
          this.externalFilters.push(filter);
        }
      });
    }

    this.gridApi.setFilterModel(filterModel);
    if (triggerChange) {
      // You can uncomment this if needed
      // this.gridApi.onFilterChanged();
    }
  }

  refreshGrid() {
    this.gridQuery.refetch();
  }

  deselectAll() {
    this.gridApi.deselectAll();
  }

  deleteQueue(id: string) {
    this.queues = this.queues.filter((q) => q.id !== id);
    if (this.queues.length > 0) {
      this.filterGrid(this.queues[0].filter, false);
    } else {
      this.filterGrid(null, false);
    }
    this.queueService.deleteQueue(id).toPromise();
  }

  clearSelection() {
    this.gridApi.deselectAll();
  }

  isRowMaster = (dataItem) => {
    return true;
  };

  get getGridRecordCount() {
    return this.gridApi.getDisplayedRowCount();
  }

  setupGridOptions() {
    if (this.gridOptions) {
      return;
    }
    const _this = this;

    this.gridOptions = {
      frameworkComponents: {
        draggableCellRenderer: DraggableCellRenderer,
        buttonRenderer: ButtonRendererComponent,
      },

      onSelectionChanged: (row) => {
        const selectedRows = this.gridOptions?.api?.getSelectedRows();
        _this.selectedRow = selectedRows;
        this.onSelect.emit(selectedRows);
      },

      onRowDoubleClicked: (row) => {
        this.onDoubleClick.emit(row.data);
      },
      onGridReady: async (e) => {
        this.isGridReady = true;
        this.gridready.emit(true);
        _this.gridApi = e.api;
        e.api.addGlobalListener((type, event) => {
          if (type === 'gridDestroyed') {
            _this.gridApi = null;
          }
        });
        _this.handleModeUpdateAndScrollEvent();
        await _this.getColumnDefs();
        _this.initCustomerCellRenders();
        if (!_this.hideQueues) {
          _this.getQueues();
        } else {
          this.gridApi.setDatasource(this.gridDS());
          if (this.displayFilters) {
            this.filterGrid(this.displayFilters, false);
          }
        }
      },

      //enableServerSideFilter: true,
      cacheBlockSize: 50,
      rowModelType: 'infinite',
      //     rowModelType: this.data ? 'clientSide' : 'infinite',
      //    serverSideStoreType: 'partial',
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        suppressMenu: true,

        filterParams: { buttons: ['reset'], debounceMs: 800 },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          debounceMs: 200,
        },
      },
    };
    if (this.rowSelection === 'multiple') {
      this.gridOptions.rowSelection = 'multiple';
    }
  }

  gridDS() {
    let me = this;
    return {
      rowCount: null,
      getRows: function (params) {
        if (me.data) {
          return;
        }
        if (me.gridApi) {
          const gridFilters = me.gridUtilService.normalizeGridFilter(
            params.filterModel,
          );
          const orderBy = me.gridUtilService.normalizeGridSort(
            params.sortModel,
          );

          // Combine all filters
          let combinedFilters = [
            ...(me.filters || []),
            ...gridFilters,
            ...me.externalFilters,
          ];

          // Remove duplicates
          combinedFilters = combinedFilters.filter(
            (filter, index, self) =>
              index ===
              self.findIndex(
                (f) => f.column === filter.column && f.value === filter.value,
              ),
          );

          const search: IPaginatedFilter = {
            first: 50,
            skip: params.startRow,
            after: params.startRow ? me.lastRowId : null,
            filter: combinedFilters,
            orFilter: me.orFilters,
            orderBy,
          };

          me.gridApi.showLoadingOverlay();
          const sub = me.getQueryData(me.object, search);
          me.gridQuery = sub;
          if (me.data) {
            params.successCallback(me.data, me.data.length);
            me.updateQueueCountFromGrid(search, me.data.length);
            me.gridApi.hideOverlay();
            return;
          }

          me.onSelect.emit(null);

          if (me.activeSubObs) {
            me.activeSubObs.unsubscribe();
          }

          me.sub.sink = me.activeSubObs = sub.valueChanges.subscribe(
            ({ loading, data }) => {
              me.gridApi.hideOverlay();
              if (!data) {
                me.gridApi.showNoRowsOverlay();
                return;
              }
              const rtn = Object.values(data)[0]; //(<any>data).getOrders;
              const { totalCount } = rtn;
              if (loading) {
                this.isLoading = loading;
                return;
              }

              if (rtn.length == 0) {
                me.gridApi.showNoRowsOverlay();
              }

              me.totalRecordCount.emit(totalCount);
              const objs = [];
              rtn.edges.forEach(({ node }) => {
                objs.push({ ...node });
              });
              me.lastRowId = objs[objs.length - 1]?.id;
              me.updateQueueCountFromGrid(search, totalCount);
              const lastRow =
                params.endRow >= totalCount ? totalCount : undefined;
              params.successCallback(objs, lastRow);
              me.allRecords.emit(objs);
              if (objs.length == 0) {
                me.gridApi.showNoRowsOverlay();
              }
              me.setupFirstRowSelector();
            },
            (error) => {
              console.log(error);
              me.gridApi.hideOverlay();
              me.gridApi.showNoRowsOverlay();
              params.successCallback([], 0);
            },
          );
          sub.refetch();
        }
      },
    };
  }

  updateAllQueueCounts(forceAll = false) {
    const me = this;
    const qList: object[] = [];

    this.sub.sink = from(this.queues)
      .pipe(mergeMap((gf: any) => me.updateQueueCount(gf)))
      .subscribe();
  }

  updateQueueCount(gf) {
    let filter: any = gf.filter;

    filter = [...filter, ...(this.filters || [])];

    const search = {
      filter: filter,
      countOnly: true,
    };

    this.sub.sink = this.getQueryData(
      this.object,
      search,
    ).valueChanges.subscribe(({ data }) => {
      const rtn: any = Object.values(data)[0]; //(<any>data).getOrders;
      const { totalCount } = rtn;
      gf.lastUpdate = moment().toDate();
      gf.recordCount = totalCount;
      return of(true);
    });
    return of(true);
  }

  getQueryData(object, search) {
    return this.queueService.getQueryData(object, search);
  }

  private updateQueueCountFromGrid(search, newCount) {
    let { filter } = search;

    filter = filter?.filter((f) => {
      return !this.filters?.some((ff) => {
        return ff.column == f.column && ff.value == f.value;
      });
    });
    let wasFound = false;
    this.queues.forEach((q) => {
      q.pressed = false;
      const isSame = this.queueService.queuesAreSame(q.filter, filter);
      if (isSame) {
        q.recordCount = newCount;
        q.pressed = true;
        wasFound = true;
      }
    });
    if (!wasFound) {
      this.adhocFilter = {
        filter: filter,
        recordCount: newCount,
        name: '',
        type: 'Private',
      };
    } else {
      this.adhocFilter = {
        filter: null,
        recordCount: newCount,
      };
    }
  }

  addQueue(queue) {
    const dto: DtoAddQueue = {
      name: queue.name,
      type: queue.type,
      viewType: this.viewType,
      filter: queue.filter,
      object: this.object,
      page: this.page,
    };
    this.sub.sink = this.queueService.addQueue(dto).subscribe((res) => {
      this.queueQuery.refetch();
      setTimeout(() => {
        this.gridApi.onFilterChanged();
      }, 1000);
    });
  }

  async updateObjectColumnLayout(columns = null, buttons = null) {
    const dto = {
      object: this.object,
      columns,
      buttons,
      page: this.page,
    };

    await this.dynamicListViewService.updateObjectColumnLayout(dto).toPromise();
    this.getColumnDefs();
    this.refreshGrid();
  }

  newObject(event) {
    this.onNewObject.emit(event);
  }
}
