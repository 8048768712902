<h4>{{ equipment?.equipmentNum }}</h4>
<p class="text-hint">{{ equipment?.description }}</p>

<nb-tabset (changeTab)="onTabChange($event)">
  <nb-tab tabTitle="Details">
    @if (equipment?.issueReported || equipment?.issueNotes) {
      <nb-alert status="warning" class="eq-alert">
        <nb-icon style="font-size: 30px" icon="alert-circle"></nb-icon>
        <strong class="mr-2">Equipment Issue: </strong>
        <nb-tag
          *ngFor="let issue of equipmentIssues?.split(',')"
          [text]="issue"
          size="small"
          removable
          class="mr-2"
          (remove)="updateIssueReported($event)"
        ></nb-tag>
        @if (equipment.issueNotes) {
          <nb-tag
            class="mr-2"
            [text]="'NOTES: ' + equipment.issueNotes"
            removable
            size="small"
            (remove)="updateIssueNotes('')"
          ></nb-tag>
        }
        <span class="flex-expand"></span>
        <button
          (click)="clearAllIssues()"
          nbButton
          size="small"
          status="control"
        >
          Clear All
        </button>
      </nb-alert>
    }
    <form [formGroup]="form" autocomplete="off" (ngSubmit)="updateEquipment()">
      <nb-accordion multi #equipmentAccordion accordion-shadow="false">
        <nb-accordion-item
          #general
          expanded
          (collapsedChange)="onGeneralClosed($event)"
          [collapsed]="generalClosed()"
        >
          <nb-accordion-item-header>
            <div class="item-container">
              <div>General</div>
              <div class="item-tags" *ngIf="general.expanded">
                <button
                  nbButton
                  size="tiny"
                  status="primary"
                  ghost
                  (click)="toggleGeneralShowMore($event)"
                >
                  {{ generalShowMore() ? 'Show Less' : 'Show More' }}
                </button>
                &nbsp;
              </div>
              <div class="item-tags" *ngIf="general.collapsed">
                <nb-tag
                  size="tiny"
                  [text]="equipment?.make"
                  nz-tooltip
                  nzTooltipTitle="Make"
                >
                </nb-tag>
                <nb-tag
                  size="tiny"
                  [text]="equipment?.model"
                  nz-tooltip
                  nzTooltipTitle="Model"
                >
                </nb-tag>
                <nb-tag
                  size="tiny"
                  [text]="equipment?.serial"
                  nz-tooltip
                  nzTooltipTitle="Serial No."
                >
                </nb-tag>
              </div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <nb-card>
              <nb-card-body>
                <div class="row">
                  <div class="col">
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Equipment No.</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          [value]="equipment?.equipmentNum"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Description</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          [value]="equipment?.description"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Fleet No.</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Serial No.</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          formControlName="serial"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Make</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          formControlName="make"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Model</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          formControlName="model"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Year</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2" [hidden]="!generalShowMore()">
                      <div class="col-4 leaders">
                        <label class="label">Vendor Order No.</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Notes</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          formControlName="notes"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Fleet Type</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          formControlName="fleetType"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Fleet Code</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          formControlName="fleetCode"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Agreement Status</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Physical Status</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Equipment Status</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2" [hidden]="!generalShowMore()">
                      <div class="col-4 leaders">
                        <label class="label">Equipment Category</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2" [hidden]="!generalShowMore()">
                      <div class="col-4 leaders">
                        <label class="label">Equipment Group</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Branch</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Department</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-accordion-item-body>
        </nb-accordion-item>
        <nb-accordion-item #currentLocation>
          <nb-accordion-item-header>
            <div class="item-container">Current Location</div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <nb-card>
              <nb-card-body>
                <div class="row">
                  <div class="col">
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Location Type</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Location No.</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          [value]="equipment?.locationNum"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Location Name</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-4 leaders">
                        <label class="label">Location Address</label>
                      </div>
                      <div class="col">
                        <textarea
                          nbInput
                          fullWidth
                          id="loctationAddress"
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          rows="3"
                          [value]="
                            equipment?.customer
                              ? equipment?.customer?.address1 +
                                ' ' +
                                equipment?.customer?.city +
                                ', ' +
                                equipment?.customer?.state +
                                ' ' +
                                equipment?.customer?.zipcode
                              : 'NO ADDRESS PROVIDED'
                          "
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Warehouse Location</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-accordion-item-body>
        </nb-accordion-item>
        <nb-accordion-item #purchase expanded>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Transfers</div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            @if (equipment.id) {
              <next-object-list
                object="EquipmentTransferLine"
                height="200"
                [filters]="[
                  {
                    column: 'equipment.id',
                    value: equipment?.id,
                  },
                ]"
              ></next-object-list>
            }
          </nb-accordion-item-body>
        </nb-accordion-item>
        <!-- <nb-accordion-item #purchase expanded>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Purchase</div>
              <div class="item-tags" *ngIf="purchase.collapsed">
                <nb-tag
                  size="tiny"
                  [text]="equipment?.customer?.customerNum"
                  nz-tooltip
                  nzTooltipTitle="Customer No."
                >
                </nb-tag>
                <nb-tag
                  size="tiny"
                  [text]="equipment?.customer?.name"
                  nz-tooltip
                  nzTooltipTitle="Customer Name"
                >
                </nb-tag>
              </div> 
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <nb-card>
              <nb-card-body>
                <div class="row">
                  <div class="col">
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Vendor No.</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          [value]="equipment?.customer?.customerNum"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Vendor Name</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          [value]="equipment?.customer?.name"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Vendor Position No.</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Purchase Date</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Estimated Receipt</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Factory Ship Date</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Receipt Date</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-accordion-item-body>
        </nb-accordion-item>
        <nb-accordion-item #sales expanded>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Sales</div>
              <div class="item-tags" *ngIf="sales.collapsed">
                <nb-tag
                  size="tiny"
                  [text]="equipment?.customer?.customerNum"
                  nz-tooltip
                  nzTooltipTitle="Ship To No."
                ></nb-tag>
                <nb-tag
                  size="tiny"
                  [text]="equipment?.customer?.name"
                  nz-tooltip
                  nzTooltipTitle="Ship To Name"
                ></nb-tag>
                <nb-tag
                  size="tiny"
                  [text]="equipment?.customer?.billTo?.customerNum"
                  nz-tooltip
                  nzTooltipTitle="Bill To No."
                ></nb-tag>
                <nb-tag
                  size="tiny"
                  [text]="equipment?.customer?.billTo?.name"
                  nz-tooltip
                  nzTooltipTitle="Bill To Name"
                ></nb-tag>
              </div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <nb-tabset (changeTab)="onTabChange($event)">
              <nb-tab tabTitle="General">
                <nb-card>
                  <nb-card-body>
                    <div class="row">
                      <div class="col">
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Ship To Customer</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Ship to Address</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Bill To Customer</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                              [value]="equipment?.customer?.billTo?.name"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Bill to Address</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Payment Type</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Salesperson</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Order Date</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Retail Order Date</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Est. Delivery Date</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Delivery Date</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Installation Date</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Sold Date</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </nb-card-body>
                </nb-card>
              </nb-tab>
              <nb-tab tabTitle="Finance">
                <nb-card>
                  <nb-card-body>
                    <div class="row">
                      <div class="col">
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Finance Company</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Contract Application</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Contract Setup No.</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Lease Start Date</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-4 leaders">
                            <label class="label">Lease End Date</label>
                          </div>
                          <div class="col">
                            <input
                              type="text"
                              nbInput
                              fullWidth
                              autocomplete="off"
                              fieldSize="small"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </nb-card-body>
                </nb-card>
              </nb-tab>
            </nb-tabset>
          </nb-accordion-item-body>
        </nb-accordion-item> -->
        <nb-accordion-item #service expanded>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Service</div>
              <div class="item-tags" *ngIf="service.collapsed">
                <nb-tag
                  size="tiny"
                  text="943.5 Hours"
                  nz-tooltip
                  nzTooltipTitle="Hour Meter"
                ></nb-tag>
                <nb-tag
                  size="tiny"
                  text="Full Maintenance"
                  nz-tooltip
                  nzTooltipTitle="Contract Type"
                ></nb-tag>
                <nb-tag
                  size="tiny"
                  text="C00781"
                  nz-tooltip
                  nzTooltipTitle="Contract No."
                ></nb-tag>
              </div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <nb-card>
              <nb-card-body>
                <div class="row">
                  <div class="col">
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Hour Meter 1</label>
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          value="943.5"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Hour Meter 2</label>
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Hour Meter 3</label>
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Hour Meter 4</label>
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Contract No.</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          value="C00781"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Contract Type</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          value="Full Maintenance"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Contract Start</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Contract End</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Last Reading M1</label>
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Last Reading M2</label>
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Last Reading M3</label>
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Last Reading M4</label>
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Next PM Date</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          value="C00781"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Next PM Hours</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                          value="Full Maintenance"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Last PM Date</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Last PM Hours</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          nbInput
                          fullWidth
                          autocomplete="off"
                          fieldSize="small"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-accordion-item-body>
        </nb-accordion-item>
        <!-- <nb-accordion-item #warranty expanded>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Warranty</div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <next-object-list
              [height]="200"
              [data]="equipment?.equipmentOptions || []"
              [hideToolBar]="true"
              [hideQueues]="true"
              [object]="'Equipment_Option'"
              class="margin-bottom:40px;"
            ></next-object-list>
          </nb-accordion-item-body>
        </nb-accordion-item> -->
        <nb-accordion-item #equipmentSpecifications expanded>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Equipment Specifications</div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <next-object-list
              [height]="200"
              [data]="equipment?.equipmentOptions || []"
              [hideToolBar]="true"
              [hideQueues]="true"
              [object]="'Equipment_Option'"
              class="margin-bottom:40px;"
            ></next-object-list>
          </nb-accordion-item-body>
        </nb-accordion-item>
        <nb-accordion-item #attachedEquipment expanded>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Attached Equipment</div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            @if (equipment?.id) {
              <next-object-list
                [height]="200"
                [hideToolBar]="true"
                [hideQueues]="true"
                [object]="'Equipment'"
                [filters]="[{ column: 'parentId', value: equipment?.id }]"
                class="margin-bottom:40px;"
              ></next-object-list>
            }
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>

      <!--<div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="serial" class="label">Serial</label>
                    <input
                      type="text"
                      nbInput
                      fullWidth
                      id="serial"
                      formControlName="serial"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="make" class="label">Make</label>
                    <input
                      type="text"
                      nbInput
                      fullWidth
                      id="make"
                      formControlName="make"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="model" class="label">Model</label>
                    <input
                      type="text"
                      nbInput
                      fullWidth
                      id="model"
                      formControlName="model"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="fleettype" class="label">Fleet Type</label>
                    <input
                      type="text"
                      nbInput
                      fullWidth
                      id="fleettype"
                      formControlName="fleetType"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="lastMeterReading" class="label"
                      >Meter Reading</label
                    >
                    <input
                      type="text"
                      nbInput
                      fullWidth
                      id="lastMeterReading"
                      formControlName="lastMeterReading"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="externalId" class="label">External Id</label>
                    <input
                      type="text"
                      nbInput
                      fullWidth
                      id="externalId"
                      formControlName="externalId"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8">
              <label for="customerType" class="label"
                >Customer #
                <nb-icon
                  icon="search-outline"
                  status="primary"
                  style="font-size: 1.2em"
                >
                </nb-icon
              ></label>
              <next-customerinput
                [customer]="customer"
                (onCustomerChange)="customerChange($event)"
              >
              </next-customerinput>
              <label for="customerType" class="label">Customer #</label>
                            <div>
                                <input nbInput fullWidth formControlName="customerName" class="customerInput"
                                    [nbPopover]="customerPopOver" [nbPopoverContext]="{id: form?.value?.customerId}"
                                    [nbPopoverTrigger]="form?.value?.customerId ? 'hover' : 'noop'">
                                <button type="button" nbSuffix nbButton status="primary" class="billToSearch">
                                    <nb-icon icon="search-outline">
                                    </nb-icon>
                                </button>
                            </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="inputFirstName" class="label"
                  >Customer Equipment#</label
                >
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="inputFirstName"
                  formControlName="customerEquipmentNum"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="notes" class="label">Notes</label>
                <textarea
                  type="textarea"
                  nbInput
                  fullWidth
                  id="notes"
                  formControlName="notes"
                  autocomplete="off"
                  rows="2"
                ></textarea>
              </div>
            </div>
          </div>-->
      <!-- <nb-card-footer>
          <div class="header-container">
            <span class="flex-expand"></span>
            <button nbButton status="primary" type="submit">Update</button>
          </div>
        </nb-card-footer> -->
    </form>
    <!--<br />
    <h5>Equipment Options</h5>
    <next-object-list
      [height]="300"
      [data]="equipment?.equipmentOptions || []"
      [hideToolBar]="true"
      [hideQueues]="true"
      [object]="'Equipment_Option'"
      class="margin-bottom:40px;"
    >
    </next-object-list>-->
  </nb-tab>
  <nb-tab #nolContainer tabTitle="Work Orders">
    @if (showWorkOrders && this.id) {
      <next-object-list
        [height]="400"
        (onDoubleClick)="gotoWorkOrder($event)"
        [hideToolbar]="true"
        [hideQueues]="true"
        [filters]="[{ column: 'equipment.id', value: this.id }]"
        [object]="'WorkOrder'"
      ></next-object-list>
    }
  </nb-tab>
  <nb-tab tabTitle="Checklists">
    @if (showChecklists && this.id) {
      <next-checklist-detail [equipmentId]="this.id"></next-checklist-detail>
    }
  </nb-tab>
  <!-- <nb-tab tabTitle="Contracts"> GM / PM / Warranty </nb-tab> -->
  <nb-tab tabTitle="Attachments">
    @if (showAttachments && this.id) {
      <next-attachment [object]="'Equipment'" [refId]="id"></next-attachment>
    }
  </nb-tab>
</nb-tabset>
