import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { EquipmentService } from '../../../@core/services/equipment.service';
import { CustomerPopoverComponent } from '../../../shared/component/customers/popover/customer-popover.component';
import { MapService } from '../../../@core/services/map.service';
import { WindowService } from '../../../@core/services/window.service';
import { WorkOrderDetailComponent } from '../../workorder/detail/workorderdetail.component';

@Component({
  selector: 'next-equipment-detail',
  templateUrl: './equipmentdetail.component.html',
  styleUrls: ['./equipmentdetail.component.scss'],
})
export class EquipmentDetailComponent implements OnInit, OnDestroy {
  private _id: string;
  @Input() set id(id: string) {
    this._id = id;
    if (!id) return;
    this.getEquipment(id);
  }
  get id() {
    return this._id;
  }

  customerPopOver = CustomerPopoverComponent;
  equipment: any = {};
  customer: any;
  activeTab = 'Details';
  equipmentIssues = '';

  // Tab visibility flags
  showWorkOrders = false;
  showAttachments = false;
  showChecklists = false;
  equipmentIssueNotes = '';
  paramSub$;
  generalClosed = signal(false);
  currentLocationClosed = signal(false);
  purchaseClosed = signal(false);
  salesClosed = signal(false);
  serviceClosed = signal(false);
  generalShowMore = signal(false);
  currentLocationShowMore = signal(false);
  purchaseShowMore = signal(false);
  salesShowMore = signal(false);
  serviceShowMore = signal(false);
  windowRef;
  form = new FormGroup({
    id: new FormControl(''),
    serial: new FormControl(''),
    make: new FormControl(''),
    model: new FormControl(''),
    fleetType: new FormControl(''),
    notes: new FormControl(''),
    lastMeterReading: new FormControl(''),
    lasteMeterReadingDate: new FormControl(''),
    customerEquipmentNum: new FormControl(''),
    fleetCode: new FormControl(''),
    locationNum: new FormControl(''),
  });
  serviceVisit: any;

  constructor(
    private readonly equipmentService: EquipmentService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly windowService: WindowService,
  ) {
    this.equipment.customer = {};
    if (this.id) {
      return;
    }
    this.paramSub$ = this.activatedRoute.params.subscribe((params) => {
      const id = params['id'];
      this.id = id;
      this.getEquipment(id);
    });
  }

  ngOnInit(): void {
    // Initialize any required data
    if (this.id) {
      this.getEquipment(this.id);
    }
  }

  ngOnDestroy() {
    if (this.paramSub$) this.paramSub$.unsubscribe();
  }

  customerChange(customer: any) {
    this.customer = customer;
  }

  onTabChange(event) {
    this.activeTab = event.tabTitle;

    // Set visibility flag for the selected tab to true if not already set
    switch (event.tabTitle) {
      case 'Work Orders':
        if (!this.showWorkOrders) this.showWorkOrders = true;
        break;
      case 'Attachments':
        if (!this.showAttachments) this.showAttachments = true;
        break;
      case 'Checklists':
        if (!this.showChecklists) this.showChecklists = true;
        break;
    }
  }

  gotoWorkOrder(workOrder) {
    const id = workOrder.id;
    this.windowService.openWindow(WorkOrderDetailComponent, {
      objectId: id,
      context: { id },
    });
  }

  updateIssueReported(tag) {
    const issue = tag?.text;
    const issuesArray = this.equipmentIssues
      ? this.equipmentIssues?.split(',')
      : [];

    const index = issuesArray.indexOf(issue);
    if (index > -1) {
      issuesArray.splice(index, 1);
    } else {
      issuesArray.push(issue);
    }
    issuesArray.sort();
    this.equipmentIssues = issuesArray.join(',');
    this.updateEquipmentIssues();
  }

  updateIssueNotes(notes) {
    this.equipmentIssueNotes = notes;
    this.updateEquipmentIssues();
  }

  clearAllIssues() {
    this.equipmentIssues = '';
    this.equipmentIssueNotes = '';
    this.updateEquipmentIssues();
  }

  async updateEquipmentIssues() {
    const _ = await this.equipmentService
      .updateEquipment({
        id: this.id,
        issueReported: this.equipmentIssues,
        issueNotes: this.equipmentIssueNotes,
      })
      .toPromise();
  }

  getEquipment(id) {
    if (!id) return;
    this.equipmentService.getEquipmentById(id).subscribe(({ data }) => {
      const equipment: any = data;
      this.equipment = equipment.getEquipmentById;
      this.form.reset();
      this.form.patchValue(this.equipment);
      this.equipmentIssueNotes = this.equipment.issueNotes;
      this.equipmentIssues = this.equipment.issueReported;
      this.customer = this.equipment.customer;
      if (this.windowRef) {
        this.windowRef.config.title = `${this.equipment?.equipmentNum} - ${this.equipment?.serial}`;
      }
    });
  }

  updateEquipment() {
    const update = this.form.value;
    update.lasteMeterReadingDate = undefined;
  }

  onGeneralClosed(event: any) {
    this.generalClosed.set(event);
  }

  onCurrentLocationClosed(event: any) {
    this.currentLocationClosed.set(event);
  }

  onPurchaseClosed(event: any) {
    this.purchaseClosed.set(event);
  }

  onSalesClosed(event: any) {
    this.salesClosed.set(event);
  }

  onServiceClosed(event: any) {
    this.serviceClosed.set(event);
  }

  toggleGeneralShowMore(event: any) {
    this.generalShowMore.set(!this.generalShowMore());
    event.stopPropagation();
  }

  toggleCurrentLocationShowMore(event: any) {
    this.currentLocationShowMore.set(!this.currentLocationShowMore());
    event.stopPropagation();
  }

  togglePurchaseShowMore(event: any) {
    this.purchaseShowMore.set(!this.purchaseShowMore());
    event.stopPropagation();
  }

  toggleSalesShowMore(event: any) {
    this.salesShowMore.set(!this.salesShowMore());
    event.stopPropagation();
  }

  toggleServiceShowMore(event: any) {
    this.serviceShowMore.set(!this.serviceShowMore());
    event.stopPropagation();
  }
}

//TODO ADD EQUIPMENT COMPONENT AND TO CUSTOMERS
