@if (viewType === 'input') {
  <div>
    <nb-form-field>
      <button
        nbPrefix
        nbButton
        ghost
        *ngIf="selectedObject && !locked && !hidePrefix && detailComponent"
        type="button"
        (click)="gotoDetail()"
        class="detailButton"
      >
        <nb-icon icon="arrow-circle-right-outline"> </nb-icon>
      </button>
      <input
        #searchInput
        nbInput
        fullWidth
        status="primary"
        [fieldSize]="fieldSize"
        class="searchInput"
        [readonly]="locked"
        [value]="displayValue"
        [nbPopover]="locked ? null : list"
        [nbPopoverPlacement]="'bottom'"
        (nbPopoverShowStateChange)="onPopOverStateChange($event)"
        (keyup)="onKeyUp($event)"
        (click)="onFocus($event)"
        (blur)="onBlur($event)"
        [placeholder]="placeholder || 'Select ' + objectType"
      />
      @if (!locked && showClearButton) {
        <button
          nbSuffix
          nbButton
          ghost
          type="button"
          (click)="clear()"
          (keydown.enter)="clear()"
        >
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      } @else {
        <button nbSuffix nbButton ghost type="button">
          <nb-icon icon="search-outline"></nb-icon>
        </button>
      }
    </nb-form-field>
  </div>
}

@if (viewType === 'select') {
  <div class="d-flex">
    <div class="clickable" [nbPopover]="locked ? null : list">
      {{ displayValue || placeholder }}

      @if (showClearButton && !locked && selectedObject) {
        <i (click)="clear()" (keydown.enter)="clear()" class="fa fa-times"></i>
      } @else {
        <i class="fa fa-caret-down"></i>
      }
    </div>
  </div>
}

<ng-template #list>
  <nb-card class="margin-bottom-0">
    <next-object-list
      [object]="objectType"
      [height]="height"
      [hideQueues]="true"
      [hideToolBar]="true"
      [filters]="filters"
      [orFilters]="orFilters"
      [page]="page"
      style="width: 1000px"
      (onDoubleClick)="selected($event)"
    >
    </next-object-list>
  </nb-card>
</ng-template>
