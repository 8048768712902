import { CommonModule, KeyValue } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DynamicListViewModule } from '../../../../shared/component/dynamiclistview/dynamiclistview.module';
import {
  NbAccordionModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDialogModule,
  NbDialogService,
  NbIconModule,
  NbInputModule,
  NbTabsetModule,
  NbToastrModule,
  NbToastrService,
  NbToggleModule,
} from '@nebular/theme';
import { AttachmentsModule } from '../../../attachments/attachments.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AttachmentComponent } from '../../../attachments/components/attachment/attachment.component';
import { ChecklistApiService } from '../services/checklistApiService.service';
import { SubSink } from 'subsink';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { AttachmentService } from '../../../attachments/services/attachment.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DynamicListViewModule,
    NbCardModule,
    NbIconModule,
    NbBadgeModule,
    NbTabsetModule,
    AttachmentsModule,
    NzAvatarModule,
    NbToggleModule,
    NbDialogModule,
    NbToastrModule,
    NbButtonModule,
    NbInputModule,
    NbCheckboxModule,
    NbAccordionModule,
  ],
  selector: 'next-checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss'],
})
export class ChecklistDetailComponent implements OnInit, OnDestroy {
  private sub = new SubSink();
  @Input() workOrderId: string;
  @Input() equipmentId: string;
  @ViewChild('attachmentComponent') attachmentComponent: AttachmentComponent;
  @ViewChild('confirmCancelTemplate') confirmCancelTemplate: TemplateRef<any>;
  @ViewChild('failActionDialog') failActionDialog: TemplateRef<any>;
  @ViewChild('parent', { static: true }) parent: ElementRef;
  @Input() set checklist(value) {
    this._checklist = value;
    if (this.checklist) {
      this.initChecklist();
    }
  }
  get checklist() {
    return this._checklist;
  }
  private _checklist: any;
  groups: any;
  groupEntries: any = [];
  attachments;
  private lineMap: Map<string, any> = new Map();
  private lightbox: PhotoSwipeLightbox | null = null;
  private pswp: PhotoSwipe | null = null;
  isEditing = false;
  editableChecklist: any;
  selectedActions: [];
  checklistFailStatuses;

  constructor(
    private readonly checklistService: ChecklistApiService,
    private readonly toastrService: NbToastrService,
    private readonly dialogService: NbDialogService,
    private readonly attachmentService: AttachmentService,
  ) {}

  ngOnInit(): void {
    this.initChecklist();
    this.setChecklistStatuses();
    this.initPhotoSwipe();
  }

  private initPhotoSwipe() {
    this.lightbox = new PhotoSwipeLightbox({
      gallery: '.checklist-container',
      children: '.checklist-image',
      pswpModule: PhotoSwipe,
    });
    this.lightbox.init();
  }

  displayImage(attachment: any) {
    this.attachmentComponent.displayImage(attachment);
    return;
  }

  ngOnDestroy() {
    if (this.pswp) {
      this.pswp.destroy();
      this.pswp = null;
    }
    this.sub.unsubscribe();
  }

  selectChecklist(checklist) {
    if (!checklist) {
      this.checklist = null;
      this.editableChecklist = null;
      this.groupEntries = [];
      return;
    }

    this.checklist = checklist[0];

    // Initialize the checklist data
    this.initChecklist();
  }

  initChecklist() {
    if (this.checklist) {
      // Create a deep copy of the checklist for editing
      this.editableChecklist = JSON.parse(JSON.stringify(this.checklist));

      // Reset and rebuild the data for the summary tab
      this.groupEntries = [];
      this.lineMap = new Map(); // Reset line map to prevent stale data
      this.buildLineMap(this.checklist.json);
      this.groupChecklistItems();

      // Make sure status data is updated
      this.setChecklistStatuses();
    }
  }

  attachmentTabClicked(e) {
    if (e.tabTitle === 'Attachments') {
      this.attachmentComponent.reloadMas();
    }
  }

  groupChecklistItems() {
    this.groups = {};
    this.lineMap?.forEach((lineObject: any, key: string) => {
      let status = lineObject.status || 'Not Completed';
      if (lineObject.informational || lineObject.type === 'input') {
        status = 'Detail';
      }
      if (!this.groups[status]) {
        this.groups[status] = [];
      }
      if (lineObject.type === 'multimeter') {
        const lines = lineObject.value.map((line: any) => {
          return {
            ...line,
            notes: line.note,
            label: 'Cell ' + line.cellId,
            id: 'cell-' + line.cellId,
            value: `${line.voltage || 0.0}v / ${line.gravity || 0.0}`,
          };
        });
        this.groups[status].push(...lines);
      } else {
        this.groups[status].push(lineObject);
      }
    });

    this.groupEntries = Object.entries(this.groups)
      .map(([name, items]) => ({ name, items }))
      .sort(
        (a, b) => this.getGroupPriority(a.name) - this.getGroupPriority(b.name),
      );
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  lineAttachments(lineId: string) {
    return this.attachments?.filter(
      (attachment: any) => attachment?.refId2 === lineId,
    );
  }
  getIconName(status: string): string {
    switch (status) {
      case 'Passed':
        return 'checkmark-circle';
      case 'Detail':
        return '';
      default:
        return 'alert-circle';
    }
  }

  valueOptionsOrder = (
    a: KeyValue<string, any>,
    b: KeyValue<string, any>,
  ): number => {
    let options = [];
    for (const [key, line] of this.lineMap) {
      if (line?.type === 'lines') {
        options = line?.options;
      }
    }
    const optionIndexA = options.findIndex((opt: any) => opt.label === a.key);
    const optionIndexB = options.findIndex((opt: any) => opt.label === b.key);
    return optionIndexA - optionIndexB;
  };

  getIconColor(status: string): string {
    switch (status) {
      case 'Passed':
        return 'success';
      case 'Detail':
        return 'medium';
      default:
        return 'danger';
    }
  }

  getGroupPriority(groupName: string): number {
    const priorities = {
      Detail: 1,
      Passed: 10,
      'Not Completed': 20,
    };
    return priorities[groupName as keyof typeof priorities] ?? 3;
  }

  changeTab(event) {
    if (event?.tabTitle === 'Attachments') {
      this.attachmentComponent.reloadMas();
    }
  }

  getBadgeColor(groupName: string): string {
    switch (groupName) {
      case 'Passed':
        return 'success';
      case 'Detail':
        return 'medium';
      default:
        return 'danger';
    }
  }
  buttonAction(action) {
    switch (action.action) {
      case 'generatePdf':
        this.generatePdf();
        break;
    }
  }

  generatePdf() {
    this.sub.sink = this.checklistService
      .generateChecklistPdf(this.checklist.id)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  buildLineMap(obj: any) {
    obj?.pages?.forEach((page: any) => {
      page?.groups?.forEach((group: any) => {
        group?.lines?.forEach((line: any) => {
          this.lineMap?.set(line.id, line);
        });
      });
    });
  }

  get currentChecklist(): any {
    return this.isEditing ? this.editableChecklist : this.checklist;
  }

  confirmCancelEdit() {
    this.dialogService
      .open(this.confirmCancelTemplate, { context: {} })
      .onClose.subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.cancelEdit();
        }
      });
  }

  onConfirmCancel(dialogRef: any) {
    dialogRef.close(true);
  }

  onDismissCancel(dialogRef: any) {
    dialogRef.close(false);
  }

  trackByAction(index: number, action: string): string {
    return `${action}-${index}`;
  }

  setChecklistStatuses() {
    this.checklistFailStatuses = this.editableChecklist?.json?.failStatuses || [
      'Immediate Action',
      'Future Attention',
    ];
  }

  onToggleChange(line: any, option: any) {
    if (!line.value || !Array.isArray(line.value)) {
      line.value = [];
    }

    line.failed = !!option.fail;
    if (line.failed) {
      line.status = this.checklistFailStatuses[0];
    } else {
      line.status = 'Passed';
    }

    const existingOptionIndex = line.value.findIndex(
      (obj: any) => obj.option === option.option,
    );

    if (existingOptionIndex > -1) {
      line.value.splice(existingOptionIndex, 1);
      this.updateLineFailureStatus(line);
      this.updateEditableChecklist(line.id, line);
    } else {
      if (!option.fail) {
        line.value = line.value.filter((selected) => {
          const isFail = line.options.some(
            (opt: any) => opt.option === selected.option && opt.fail,
          );
          return !isFail;
        });

        line.value.push({ option: option.option, actions: [] });

        this.updateLineFailureStatus(line);
        this.updateEditableChecklist(line.id, line);
      } else {
        // remove non-fail options before adding fail option
        line.value = line.value.filter(
          (selected) =>
            !line.options.some(
              (opt: any) => opt.option === selected.option && !opt.fail,
            ),
        );

        this.dialogService
          .open(this.failActionDialog, {
            context: {
              option: option,
              actions: line.actions || [],
              selectedActions: this.initializeSelectedActions(line, option),
              line: line,
            },
          })
          .onClose.subscribe((selectedActions: string[] | null) => {
            if (selectedActions && selectedActions.length > 0) {
              const valObj = line.value.find(
                (obj: any) => obj.option === option.option,
              );

              if (valObj) {
                valObj.actions = selectedActions;
              } else {
                line.value.push({
                  option: option.option,
                  actions: selectedActions,
                });
              }
              this.updateLineFailureStatus(line);
              this.updateEditableChecklist(line.id, line);
            }
          });
        return;
      }
    }
  }

  updateLineFailureStatus(line: any) {
    const hasFailOption = line.value.some((selectedOption: any) =>
      line.options.some(
        (opt: any) => opt.option === selectedOption.option && opt.fail,
      ),
    );
    if (hasFailOption) {
      line.failed = true;
    } else {
      delete line.failed;
    }
  }

  initializeSelectedActions(line: any, option: any) {
    const selectedOption = line.value.find(
      (v: any) => v.option === option.option,
    );
    const actions = selectedOption?.actions || [];

    return actions.reduce((acc: any, action: string) => {
      acc[action] = true;
      return acc;
    }, {});
  }

  isPassOption(line: any, optionValue: string): boolean {
    const opt = line.options.find((o: any) => o.option === optionValue);
    return opt ? !opt.fail : false;
  }

  isFailOption(line: any, optionValue: string): boolean {
    const opt = line.options.find((o: any) => o.option === optionValue);
    return opt ? !!opt.fail : false;
  }

  isOptionSelected(line: any, option: any): boolean {
    if (!line.value || !Array.isArray(line.value)) {
      return false;
    }
    return line.value.some((obj: any) => obj.option === option.option);
  }

  isActionSelected(line: any, optionName: string, action: string): boolean {
    const selectedOption = line.value.find((v: any) => v.option === optionName);
    return selectedOption?.actions.includes(action) || false;
  }

  getSelectedActions(context: any): string[] {
    if (!context || !context.selectedActions) {
      return [];
    }

    const selectedActions = Object.keys(context.selectedActions).filter(
      (key) => context.selectedActions[key],
    );
    return selectedActions;
  }

  onActionSelectionChange(action: string, isChecked: boolean, context: any) {
    if (!context.selectedActions) {
      context.selectedActions = {};
    }
    context.selectedActions[action] = isChecked;
  }

  applySelectedActions(line: any, option: any, actions: string[]) {
    const selectedOption = line.value.find(
      (v: any) => v.option === option.option,
    );
    if (selectedOption) {
      selectedOption.actions = actions;
    } else {
      line.value.push({ option: option.option, actions });
    }
    this.updateEditableChecklist(line.id, line);
  }

  updateEditableChecklist(lineId: string, updatedLine: any) {
    if (this.isEditing && this.editableChecklist?.json?.pages) {
      this.editableChecklist.json.pages.forEach((page: any) => {
        page.groups.forEach((group: any) => {
          const lineIndex = group.lines.findIndex(
            (line: any) => line.id === lineId,
          );
          if (lineIndex !== -1) {
            group.lines[lineIndex] = { ...updatedLine };
          }
        });
      });
    }
  }

  updateNotes(line: any, value: string) {
    line.notes = value;
  }

  updateInternalNotes(line: any, value: string) {
    line.internalNotes = value;
  }

  syncChecklistChanges() {
    if (this.isEditing && this.editableChecklist?.json?.pages) {
      this.editableChecklist.json.pages.forEach((page: any) => {
        page.groups.forEach((group: any) => {
          group.lines.forEach((line: any) => {
            const currentLine = this.lineMap.get(line.id);
            if (currentLine) {
              line.notes = currentLine.notes || '';
              line.internalNotes = currentLine.internalNotes || '';
              line.value = currentLine.value || [];
              line.actions = currentLine.actions || [];
            }
          });
        });
      });
    }
  }

  enableEdit() {
    this.isEditing = true;
  }

  trackByLineId(index: number, line: any): string {
    return line.id;
  }

  saveChecklist() {
    const dto = {
      checklistId: this.editableChecklist.id,
      json: this.editableChecklist.json,
    };
    this.sub.sink = this.checklistService.saveChecklist(dto).subscribe(
      (response: any) => {
        this.checklist = response.data.saveChecklist;
        this.initChecklist();
        this.isEditing = false;
        this.toastrService.success('Checklist updated successfully');
      },
      (error) => {
        console.error('Save failed:', error);
        this.toastrService.danger(
          'Failed to save checklist. Please try again.',
        );
      },
    );
  }

  cancelEdit() {
    this.isEditing = false;
    this.editableChecklist = JSON.parse(JSON.stringify(this.checklist));
    this.toastrService.info('Edit canceled. Changes were not saved.');
  }

  isLineComplete(line: any): boolean {
    if (line.required && (!line.value || line.value.length === 0)) {
      return false;
    }
    return true;
  }

  getFailedCount(group: any): number {
    if (!group?.lines) {
      return 0;
    }
    return group.lines.filter((line: any) => line.failed).length;
  }

  getIncompleteCount(group: any): number {
    if (!group?.lines) {
      return 0;
    }
    return group.lines.filter(
      (line: any) => !line.failed && !this.isLineComplete(line),
    ).length;
  }

  getPassedCount(group: any): number {
    if (!group?.lines) {
      return 0;
    }
    return group.lines.filter(
      (line: any) => this.isLineComplete(line) && !line.failed,
    ).length;
  }

  openDialog(template: TemplateRef<any>, attachment: any): void {
    this.dialogService
      .open(template, { context: { attachment } })
      .onClose.subscribe((result) => {
        if (result === true) {
          this.removeAttachment(attachment, template);
        }
      });
  }

  async removeAttachment(attachment, dialog) {
    this.sub.sink = this.attachmentService
      .removeAttachment(attachment.attachment.id)
      .subscribe();
    dialog.close();
  }

  getFailedOptionText(line: any): string {
    const failedValue = line.value.find((val: any) =>
      line.options.some((opt: any) => opt.option === val.option && opt.fail),
    );
    return failedValue ? failedValue.option : '';
  }

  getAllFailedValues(line: any): any[] {
    if (!line || !line.value || !line.options) {
      return [];
    }
    return line.value.filter((val: any) =>
      line.options.some((opt: any) => opt.option === val.option && opt.fail),
    );
  }
}
