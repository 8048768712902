@if (workOrderId) {
  <next-object-list
    id="checklistList"
    object="Checklist"
    [selectFirstRow]="true"
    (buttonAction)="buttonAction($event)"
    (onSelect)="selectChecklist($event)"
    [hideToolBar]="false"
    [filters]="[
      {
        column: 'workOrderId',
        value: workOrderId,
      },
    ]"
    height="200"
  ></next-object-list>
}

@if (equipmentId) {
  <next-object-list
    id="checklistList"
    object="Checklist"
    [selectFirstRow]="true"
    (buttonAction)="buttonAction($event)"
    (onSelect)="selectChecklist($event)"
    [hideToolBar]="false"
    [filters]="[
      {
        column: 'equipmentId',
        value: equipmentId,
      },
    ]"
    height="200"
  ></next-object-list>
}

<div class="action-buttons mb-3" style="float: right">
  <button nbButton status="primary" (click)="enableEdit()" *ngIf="!isEditing">
    Edit
  </button>

  <div *ngIf="isEditing" class="edit-actions">
    <button nbButton status="success" (click)="saveChecklist()" class="mr-2">
      Submit
    </button>
    <button nbButton outline (click)="cancelEdit()">Cancel</button>
  </div>
</div>

<br />
@if (editableChecklist?.id) {
  <nb-tabset (changeTab)="changeTab($event)">
    <nb-tab [active]="true" tabTitle="Summary">
      <div class="row">
        <div class="col">
          @for (group of groupEntries; track group.name) {
            <div class="d-flex align-items-center">
              <h5 class="mb-2 mt-3">
                {{ group.name }}
              </h5>
              @if (group.name !== 'Detail') {
                <!-- <nb-badge [status]="getIconColor(group.name)" class="ml-2">
              {{ group?.items?.length }}
            </nb-badge> -->
              }
            </div>
            <nb-card>
              <nb-list>
                @for (item of group.items; track item.id) {
                  <nb-list-item
                    class="d-flex w-100"
                    style="flex-direction: column"
                  >
                    <div class="d-flex">
                      @if (group.name !== 'Detail') {
                        <nb-icon
                          style="min-width: 30px"
                          [icon]="getIconName(item.status)"
                          [status]="getIconColor(item.status)"
                          class="mr-3"
                        >
                        </nb-icon>
                      }
                      <div class="flex-grow-1">
                        <strong>{{ item.label }}</strong>

                        @if (isArray(item.value)) {
                          @switch (item.type) {
                            @case ('lines') {
                              <br />
                              @for (
                                gl of item.value;
                                track $index + ':' + gl?.id;
                                let isLast = $last
                              ) {
                                <div
                                  class="d-flex mt-2 pb-1 pt-1"
                                  style="border-bottom: 1px solid grey"
                                >
                                  @for (
                                    item of gl | keyvalue: valueOptionsOrder;
                                    track $index + ':' + item.key;
                                    let isLast = $last
                                  ) {
                                    <div class="col" style="padding-right: 0px">
                                      <u>{{ item.key }}</u>
                                      <p>
                                        <b>{{ item.value?.trim() }}</b>
                                      </p>
                                    </div>
                                  }
                                </div>
                              }
                            }
                            @default {
                              @for (value of item.value; track value.option) {
                                @if (group.name !== 'Passed') {
                                  <p class="labelAction">
                                    <span>&bull; {{ value.option }}</span>
                                    <!-- <span style="padding-left: 10px; margin-bottom: 3px"> -->
                                    @if (value?.actions?.length > 0) {
                                      @for (
                                        action of value.actions;
                                        track action
                                      ) {
                                        <span class="chip">{{ action }}</span>
                                      }
                                    }
                                  </p>
                                }
                                <!-- </span>x -->
                              }
                            }
                          }
                        } @else if (group.name !== 'Passed') {
                          <p>{{ item.value }}</p>
                        }
                        @if (item.notes) {
                          <p class="notes">
                            <strong>Notes: {{ item.notes }}</strong>
                          </p>
                        }
                      </div>
                    </div>
                    @if (lineAttachments(item?.id)?.length > 0) {
                      <div class="d-flex mt-2 checklist-container">
                        <span style="width: 40px"></span>
                        @for (
                          attachment of lineAttachments(item?.id);
                          track attachment.id
                        ) {
                          @if (attachment.fileName.endsWith('mp4')) {
                            <i
                              (click)="displayImage(attachment)"
                              style="
                                font-size: 3rem;
                                margin-left: 5px;
                                color: lightcoral;
                              "
                              class="fa-regular fa-file-video"
                            ></i>
                          } @else {
                            <img
                              (click)="
                                displayImage(
                                  attachment,
                                  lineAttachments(item?.id)
                                )
                              "
                              class="checklist-image"
                              style="
                                max-height: 140px;
                                border-radius: 5px;
                                margin-left: 5px;
                                cursor: pointer;
                              "
                              [src]="attachment?.signedURL"
                              [attr.data-pswp-src]="attachment?.signedURL"
                            />
                          }
                        }
                      </div>
                    }
                  </nb-list-item>
                }
              </nb-list>
            </nb-card>
          }
        </div>
        <!-- <div [hidden]="true" class="col mt-3">
          <h5>Attachments</h5>
          <next-attachment
            #attachmentComponent
            [disableAdd]="true"
            object="Checklist"
            [refId]="checklist?.id"
            (attachments)="attachments = $event"
          ></next-attachment>
        </div> -->
      </div>
    </nb-tab>
    @for (
      page of editableChecklist?.json?.pages;
      track page.name;
      let i = $index
    ) {
      <nb-tab [active]="i === 0" [tabTitle]="page.name">
        <div class="page-container" [id]="'component-' + page.id">
          @for (group of page?.groups; track group.name) {
            <div class="group-header">
              <span class="group-name">{{ group.name }}</span>
              <div
                class="group-summary"
                style="display: flex; align-items: center"
              >
                @if (getFailedCount(group) > 0) {
                  <span
                    class="failed-summary"
                    style="display: flex; align-items: center"
                  >
                    <span>{{ getFailedCount(group) }}</span>
                    <nb-icon
                      icon="alert-circle"
                      status="danger"
                      style="margin-left: 4px"
                    ></nb-icon>
                  </span>
                }
                @if (getIncompleteCount(group) > 0) {
                  <span
                    class="incomplete-summary"
                    style="
                      display: flex;
                      align-items: center;
                      margin-left: 1rem;
                    "
                  >
                    <span>{{ getIncompleteCount(group) }}</span>
                    <nb-icon
                      icon="question-mark-circle-outline"
                      status="warning"
                      style="margin-left: 4px"
                    ></nb-icon>
                  </span>
                }
                <span
                  class="complete-summary"
                  style="display: flex; align-items: center; margin-left: 1rem"
                >
                  <span>{{ getPassedCount(group) }}</span>
                  <nb-icon
                    icon="checkmark-circle"
                    status="success"
                    style="margin-left: 4px"
                  ></nb-icon>
                </span>
              </div>
            </div>
            <nb-card class="group-card">
              <div class="group-items" [id]="'group-' + group?.id">
                <nb-accordion multi="true">
                  <nb-accordion-item
                    *ngFor="
                      let line of group?.lines;
                      trackBy: trackByLineId;
                      let i = index
                    "
                    class="checklist-item"
                    [id]="'component-' + line.id"
                  >
                    <nb-accordion-item-header>
                      <div
                        class="accordion-header-content"
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          width: 100%;
                        "
                      >
                        <span> {{ i + 1 }}. {{ line?.label }} </span>

                        <div class="right-section">
                          <div class="fail-values-container">
                            @if (isArray(line?.value)) {
                              <ng-container *ngFor="let value of line?.value">
                                <span class="failed-value">{{
                                  value?.option
                                }}</span>
                                <ng-container
                                  *ngFor="let action of value?.actions"
                                >
                                  <span class="action-badge" status="danger">{{
                                    action
                                  }}</span>
                                </ng-container>
                              </ng-container>
                            } @else {
                              <div class="failed-value">{{ line?.value }}</div>
                            }
                          </div>
                          <span class="status-icon">
                            <ng-container
                              *ngIf="
                                !isLineComplete(line);
                                else completedTemplate
                              "
                            >
                              <nb-icon
                                icon="checkmark-circle"
                                status="medium"
                              ></nb-icon>
                            </ng-container>
                            <ng-template #completedTemplate>
                              <ng-container
                                *ngIf="line.failed; else passedTemplate"
                              >
                                <nb-icon
                                  icon="alert-circle"
                                  status="danger"
                                ></nb-icon>
                              </ng-container>
                              <ng-template #passedTemplate>
                                <nb-icon
                                  icon="checkmark-circle"
                                  status="success"
                                ></nb-icon>
                              </ng-template>
                            </ng-template>
                          </span>
                        </div>
                      </div>
                    </nb-accordion-item-header>
                    <nb-accordion-item-body>
                      <div class="left-column">
                        <ng-container>
                          @switch (line.type) {
                            @case ('input') {
                              <input
                                nbInput
                                fullWidth
                                *ngIf="
                                  line?.value?.[0] && line?.value?.[0].option
                                "
                                [placeholder]="line?.placeholder"
                                [readOnly]="!isEditing"
                                [(ngModel)]="line?.value?.[0].option"
                              />
                            }
                            @case ('textarea') {
                              <textarea
                                nbInput
                                fullWidth
                                [placeholder]="line?.label"
                                [readOnly]="!isEditing"
                                [(ngModel)]="line.value[0].option"
                              ></textarea>
                            }
                            @case ('dropdown') {
                              <div class="dropdown-preview">
                                @for (
                                  option of line?.options;
                                  track option.option
                                ) {
                                  <div class="toggle-with-badge">
                                    <nb-toggle
                                      class="mr-3"
                                      [checked]="isOptionSelected(line, option)"
                                      [readOnly]="!isEditing"
                                      (checkedChange)="
                                        onToggleChange(line, option)
                                      "
                                    >
                                      {{ option.option }}
                                    </nb-toggle>
                                    @if (isOptionSelected(line, option)) {
                                      @for (
                                        selected of line.value;
                                        track selected.option
                                      ) {
                                        @if (
                                          selected.option === option.option &&
                                          selected.actions?.length > 0
                                        ) {
                                          @for (
                                            action of selected.actions;
                                            track action
                                          ) {
                                            <span class="chip">{{
                                              action
                                            }}</span>
                                          }
                                        }
                                      }
                                    }
                                  </div>
                                }
                              </div>
                            }
                            @case ('lines') {
                              <div class="lines-preview">
                                @for (
                                  field of line?.options;
                                  track field.apiName;
                                  let i = $index
                                ) {
                                  <div class="form line-field">
                                    <label>{{ field.label }}</label>
                                    @if (
                                      field.inputType === 'equipment-selector'
                                    ) {
                                      <next-equipmentinput
                                        (onEquipmentChange)="
                                          field.value = $event
                                        "
                                        [readOnly]="!isEditing"
                                      ></next-equipmentinput>
                                    } @else {
                                      <input
                                        nbInput
                                        fullWidth
                                        fieldSize="small"
                                        [readOnly]="!isEditing"
                                        [type]="field.inputType || 'text'"
                                        [placeholder]="field.placeholder"
                                        [required]="field.required"
                                      />
                                    }
                                  </div>
                                }
                              </div>
                            }
                          }
                        </ng-container>
                        @if (line.value && line.value[0]?.actions?.length > 0) {
                          @for (action of line.value[0].actions; track action) {
                            <span class="chip">{{ action }}</span>
                          }
                        }
                        @if (
                          line.imageOption === 'required' ||
                          line.type === 'image' ||
                          (line.imageOption === 'onFail' && line.failed)
                        ) {
                          <div class="mt-3">
                            <button nbButton status="primary" size="small">
                              <nb-icon icon="camera"></nb-icon>
                              {{ line.imageRequiredCount }}
                              Required Image{{
                                line.imageRequiredCount > 1 ? 's' : ''
                              }}
                            </button>
                          </div>
                        }
                        <div class="notes-row">
                          <div class="notes-container">
                            <label>Notes:</label>
                            <ng-container *ngIf="isEditing; else viewNotes">
                              <textarea
                                nbInput
                                fullWidth
                                [value]="line.notes || ''"
                                (input)="updateNotes(line, $event.target.value)"
                                placeholder="Enter notes"
                                rows="3"
                              ></textarea>
                            </ng-container>
                            <ng-template #viewNotes>
                              <p class="notes">{{ line?.notes }}</p>
                            </ng-template>
                          </div>
                          <div class="notes-container">
                            <label>Internal Notes:</label>
                            <ng-container
                              *ngIf="isEditing; else viewInternalNotes"
                            >
                              <textarea
                                nbInput
                                fullWidth
                                [value]="line.internalNotes || ''"
                                (input)="
                                  updateInternalNotes(line, $event.target.value)
                                "
                                placeholder="Enter internal notes"
                                rows="3"
                              ></textarea>
                            </ng-container>
                            <ng-template #viewInternalNotes>
                              <p class="notes">{{ line?.internalNotes }}</p>
                            </ng-template>
                          </div>
                        </div>
                        <div class="notes-container">
                          <label>Customer Response:</label>
                          <p class="notes">{{ line?.customerResponse }}</p>
                        </div>
                      </div>
                      @if (lineAttachments(line?.id)?.length > 0) {
                        <div class="d-flex mt-2 checklist-container">
                          <span style="width: 40px"></span>
                          @for (
                            attachment of lineAttachments(line?.id);
                            track attachment.id
                          ) {
                            @if (attachment.fileName.endsWith('mp4')) {
                              <i
                                (click)="displayImage(attachment)"
                                (keydown.enter)="displayImage(attachment)"
                                style="
                                  font-size: 3rem;
                                  margin-left: 5px;
                                  color: lightcoral;
                                "
                                class="fa-regular fa-file-video"
                              ></i>
                            } @else {
                              <div class="attachment-item">
                                <img
                                  (click)="displayImage(attachment)"
                                  (keydown.enter)="displayImage(attachment)"
                                  class="checklist-image"
                                  alt="Attachment"
                                  style="
                                    max-height: 140px;
                                    border-radius: 5px;
                                    margin-left: 5px;
                                    cursor: pointer;
                                  "
                                  [src]="attachment?.signedURL"
                                  [attr.data-pswp-src]="attachment?.signedURL"
                                />
                                <nb-icon
                                  *ngIf="isEditing"
                                  icon="trash-2"
                                  class="delete-attachment-icon"
                                  (click)="
                                    openDialog(
                                      dialogDeleteAttachment,
                                      attachment
                                    )
                                  "
                                  (keydown.enter)="
                                    openDialog(
                                      dialogDeleteAttachment,
                                      attachment
                                    )
                                  "
                                ></nb-icon>
                              </div>
                            }
                          }
                        </div>
                      }
                    </nb-accordion-item-body>
                  </nb-accordion-item>
                </nb-accordion>
              </div>
            </nb-card>
          }
        </div>
      </nb-tab>
    }

    <nb-tab tabTitle="Attachments">
      <next-attachment
        #attachmentComponent
        [object]="'Checklist'"
        [refId]="checklist?.id"
        [disableAdd]="true"
        (attachments)="attachments = $event"
      ></next-attachment>
    </nb-tab>
  </nb-tabset>
}

<ng-template #confirmCancelTemplate let-dialogRef="dialogRef">
  <nb-card>
    <nb-card-header class="dialog-header">Confirm Cancel</nb-card-header>
    <nb-card-body>
      <p>Are you sure you want to cancel your changes?</p>
    </nb-card-body>
    <nb-card-footer class="dialog-footer">
      <button nbButton status="warning" (click)="onConfirmCancel(dialogRef)">
        Yes
      </button>
      <button nbButton status="primary" (click)="onDismissCancel(dialogRef)">
        No
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #failActionDialog let-dialogRef="dialogRef" let-data>
  <nb-card>
    <nb-card-header class="dialog-header">Select an Action</nb-card-header>
    <nb-card-body>
      <p>
        Please select one or more actions for "{{
          data?.option?.option || 'Unknown Option'
        }}":
      </p>
      <div *ngFor="let action of data?.actions; trackBy: trackByAction">
        <nb-checkbox
          [(ngModel)]="data.selectedActions[action]"
          (checkedChange)="onActionSelectionChange(action, $event, data)"
        >
          {{ action }}
        </nb-checkbox>
      </div>
    </nb-card-body>
    <nb-card-footer class="dialog-footer">
      <button
        nbButton
        status="success"
        (click)="dialogRef.close(getSelectedActions(data))"
      >
        Confirm
      </button>
      <button nbButton outline status="primary" (click)="dialogRef.close(null)">
        Cancel
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogDeleteAttachment let-data let-ref="dialogRef">
  <nb-card accent="danger">
    <nb-card-header
      class="dialog-header"
      style="display: flex; justify-content: center"
      >Are you sure?
    </nb-card-header>
    <nb-card-body>
      <div style="display: flex; justify-content: center">
        <nb-icon
          status="danger"
          style="font-size: 4rem"
          class="delete-icon"
          icon="alert-circle-outline"
        ></nb-icon>
      </div>
      <p>This will delete the attachment</p>
    </nb-card-body>
    <nb-card-footer style="display: flex; justify-content: space-between">
      <button nbButton (click)="ref.close()">Cancel</button>
      <span class="flex-expand"></span>
      <button nbButton status="danger" (click)="removeAttachment(data, ref)">
        Delete
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
