import { gql } from 'apollo-angular';

export const Query_GetDepartmentsPaginated = gql`
  query getDepartmentsPaginated($search: DtoPaginatedFilter!) {
    getDepartmentsPaginated(search: $search) {
      edges {
        cursor
        node {
          id
          departmentNum
          name
          tenantId
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
