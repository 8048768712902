import { gql } from 'apollo-angular';

export const Mutation_UpdateServiceVisitStatus = gql`
  mutation updateServiceVisitStatus($dto: DtoUpdateServiceVisitStatus!) {
    updateServiceVisitStatus(dto: $dto) {
      id
      status
      statusRef {
        id
        color
      }
      serviceVisitWorkers {
        id
        status
        worker {
          id
          displayName
          workerNum
        }
        statusRef {
          id
          color
        }
      }
      serviceVisitWorkOrders {
        id
        workOrderStatus
        workOrder {
          id
          status
          statusRef {
            id
            status
          }
        }
      }
    }
  }
`;
