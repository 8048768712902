<button
  [style.display]="showAsButton ? '' : 'none'"
  nbButton
  status="primary"
  outline
  (click)="showFileSelect()"
>
  <!-- <nb-icon icon="upload-outline" class="mr-1"></nb-icon> -->
  <img style="width: 20px" src="/assets/img/ai.png" alt="ai" class="mr-1" />
  {{ buttonLabel || 'Upload File' }}
</button>
<input
  id="fileInput"
  style="display: none"
  type="file"
  (change)="handleSingleUpload($event)"
/>

<div #parent [style.display]="showAsButton ? 'none' : ''">
  <div *ngIf="requiredFileTypes">
    <nb-alert status="danger" *ngIf="requiredFileTypesLeft.length > 0">
      <nb-icon
        icon="alert-circle-outline"
        pack="eva"
        class="animate__animated animate__bounce animate__delay-2s"
      ></nb-icon>
      <h6 class="text-uppercase">
        {{ requiredFileTypesLeft.length }} documents require upload!
      </h6>
    </nb-alert>
    <div class="mb-3">
      <div class="chip-list">
        <div class="chip" *ngFor="let fileType of requiredFileTypes">
          <div
            *ngVar="
              fileType | arrayContains: _attachments : 'type' as isAttached
            "
          >
            <nb-icon
              class="mr-1"
              [icon]="
                !isAttached
                  ? 'alert-circle-outline'
                  : 'checkmark-circle-outline'
              "
              [status]="!isAttached ? 'danger' : 'primary'"
              pack="eva"
            ></nb-icon>
          </div>
          <span>
            {{ fileType }}
          </span>
        </div>
      </div>
    </div>
  </div>
  @if (!disableAdd) {
    <div class="center mb-2">
      <ngx-file-drop
        dropZoneLabel="Drop files here"
        (onFileDrop)="dropped($event, dialog)"
      >
        >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          Drag files here to upload, or &nbsp;
          <button
            nbButton
            size="small"
            status="primary"
            type="button"
            (click)="openFileSelector()"
          >
            Browse Files
          </button>
        </ng-template>
      </ngx-file-drop>
    </div>
  }

  <nb-card class="p-2 mb-3" [hidden]="!showToolbar">
    <div class="search-card">
      <div style="width: 350px; margin-left: 5px; margin-top: 2px">
        <nb-form-field>
          <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
          <input
            fullWidth
            nbInput
            #searchInput
            status="primary"
            placeholder="Search..."
          />
        </nb-form-field>
      </div>
      <div
        class="pt-1 pb-0 ml-3"
        style="margin-top: 2px"
        *ngIf="_associatedObjects?.length > 0"
      >
        <nb-toggle
          (checkedChange)="toggleAssociatedAttachments($event)"
          labelPosition="end"
          status="primary"
          checked
          >Show Associated</nb-toggle
        >
      </div>
      <div
        class="pt-1 pb-0 ml-3"
        style="margin-top: 2px"
        *ngIf="object === 'Equipment'"
      >
        <nb-toggle
          (checkedChange)="toggletruckFileOnly($event)"
          labelPosition="end"
          status="primary"
          value="true"
          >Show only truck files</nb-toggle
        >
      </div>
      <span></span>
      <button nbButton ghost (click)="refreshAll()">
        <nb-icon icon="refresh"></nb-icon>
      </button>
      <div *ngIf="showAsGallery">
        <button nbButton ghost (click)="adjustImageSize(-1)">
          <nb-icon icon="minus"></nb-icon>
        </button>
        <button nbButton ghost (click)="adjustImageSize(1)">
          <nb-icon icon="plus"></nb-icon>
        </button>
      </div>

      <nb-button-group filled>
        <button
          nbButtonToggle
          (pressedChange)="reloadMas()"
          [(pressed)]="showAsGallery"
        >
          <nb-icon icon="image-outline"></nb-icon>
        </button>
        <button nbButtonToggle [(pressed)]="showAsList">
          <nb-icon icon="list-outline"></nb-icon>
        </button>
      </nb-button-group>
    </div>
  </nb-card>

  <div [hidden]="!showAsGallery" style="height: 100%">
    <div *ngIf="filteredAttachments?.length === 0">
      <div class="text-center grey mt-4">
        <nb-icon
          icon="file-outline"
          style="font-size: 3em"
          pack="eva"
        ></nb-icon>
        <div class="mt-2 mb-5">No attachments found</div>
      </div>
    </div>

    @if (filteredAttachments?.length > 0) {
      <ngx-masonry style="margin-top: -10px; min-height: 300px" #mas>
        <div
          ngxMasonryItem
          class="masonry-item"
          [style.width.px]="imageWidth + 45"
          [style.min-height.px]="200"
          *ngFor="let attachment of filteredAttachments"
        >
          <nb-card
            [nbSpinner]="attachment.imageError"
            nbSpinnerStatus="primary"
          >
            <nb-badge
              [text]="attachment.fileName.slice(0, 30)"
              status="control"
              position="bottom left"
              nbTooltip="File name"
            >
            </nb-badge>

            <nb-card-body>
              <label class="w-100">
                <div class="attachmentType">
                  {{ attachment.type }}
                  <span class="float-right download" style="margin-right: -8px">
                    <a nz-dropdown [nzDropdownMenu]="menu">
                      <nb-icon
                        status="basic"
                        icon="more-vertical-outline"
                      ></nb-icon>
                    </a>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item>
                          <a [href]="attachment?.signedURL" class="nostyle">
                            <nb-icon
                              icon="download-outline"
                              class="mr-1"
                            ></nb-icon>
                            Download
                          </a>
                        </li>
                        @if (allowDelete) {
                          <li nz-menu-divider></li>
                          <li
                            nz-menu-item
                            nzDanger
                            (click)="
                              openDialog(dialogDeleteAttachment, attachment)
                            "
                          >
                            <nb-icon
                              icon="trash-outline"
                              class="mr-1"
                            ></nb-icon>
                            Delete
                          </li>
                        }
                      </ul>
                    </nz-dropdown-menu>
                  </span>
                </div>

                <div
                  class="badges"
                  *ngIf="
                    attachment?.label ||
                    attachment?.associatedObject ||
                    attachment?.isTruckFile
                  "
                >
                  <nb-badge
                    [text]="attachment?.label"
                    status="primary"
                    *ngIf="attachment?.label"
                    nbTooltip="Attachment Label"
                  >
                  </nb-badge>
                  <nb-badge
                    [text]="attachment?.associatedObject"
                    status="warning"
                    *ngIf="attachment?.associatedObject"
                    nbTooltip="Associated Document"
                  ></nb-badge>
                  <nb-badge
                    text="Truck File"
                    status="info"
                    position="none"
                    *ngIf="attachment?.isTruckFile"
                  ></nb-badge>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="userName">
                    {{ attachment?.createdBy?.fullName }}
                  </div>

                  <div class="createdAt">
                    {{ attachment.createdAt | date: 'short' }}
                  </div>
                </div>
              </label>
              <hr />
              <next-attachment-image
                #masAttachment
                [attachment]="attachment"
                [width]="imageWidth"
                style="min-height: 200px"
                (showImage)="displayImage(attachment)"
                (onImageLoaded)="reloadMas()"
              ></next-attachment-image>
              <div class="pswp-caption-content">TEST</div>
            </nb-card-body>
          </nb-card>
        </div>
      </ngx-masonry>
    }
  </div>

  <div [hidden]="!showAsList">
    <nb-card class="mt-3">
      <nb-card-body>
        <ag-grid-angular
          style="width: 1000%"
          style.height="{{ 600 }}px"
          [rowData]="filteredAttachments"
          [columnDefs]="columnDefs"
          [gridOptions]="gridOptions"
          class="ag-theme-balham"
        >
        </ag-grid-angular>
      </nb-card-body>
    </nb-card>
  </div>

  <nb-card
    *ngIf="isLoading"
    [nbSpinner]="isLoading"
    nbSpinnerStatus="primary"
    style="height: 100px"
  >
  </nb-card>
</div>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Upload Files</nb-card-header>
    <nb-card-body>
      <div class="upload-table">
        <table class="table">
          <thead>
            <tr>
              <td>Files Name</td>
              <td>
                Attachment Type
                <p>
                  <nb-select
                    placeholder="Attachment Type"
                    size="small"
                    (selectedChange)="toggleAllAttachmentType($event)"
                  >
                    <nb-option
                      *ngFor="let value of requiredFileTypes || documentTypes"
                      [value]="value"
                      >{{ value }}</nb-option
                    >
                  </nb-select>
                </p>
              </td>
              <td class="center" *ngIf="object === 'Equipment'">
                Truck File
                <p>
                  <nb-checkbox
                    (checkedChange)="toggleAll($event)"
                  ></nb-checkbox>
                </p>
              </td>
              <td>Notes</td>
              <td>Remove</td>
            </tr>
          </thead>
          <tbody class="upload-name-style">
            <tr *ngFor="let item of uploadFiles; let i = index">
              <td style="width: 450px">
                <input
                  nbInput
                  fullWidth
                  type="text"
                  [(ngModel)]="item.relativePath"
                />
              </td>
              <td>
                <nb-select
                  placeholder="Attachment Type"
                  [(selected)]="item.attachmentType"
                >
                  <nb-option
                    *ngFor="let value of requiredFileTypes || documentTypes"
                    [value]="value"
                    >{{ value }}</nb-option
                  >
                </nb-select>
              </td>
              <td class="center" *ngIf="object === 'Equipment'">
                <nb-checkbox [checked]="item?.truckFile"></nb-checkbox>
              </td>
              <td>
                <textarea
                  type="text"
                  nbInput
                  class="form-control"
                  placeholder="Notes"
                  [(ngModel)]="item.notes"
                ></textarea>
              </td>
              <td
                class="center"
                [nbSpinner]="item.status === 'Uploading'"
                nbSpinnerStatus="warning"
              >
                <button
                  *ngIf="!item.status"
                  nbButton
                  ghost
                  status="danger"
                  (click)="removeFile(i)"
                >
                  <nb-icon icon="trash-2-outline"></nb-icon>
                </button>
                <div></div>
                <div *ngIf="item.status === 'Uploaded'">
                  <nb-icon status="success" icon="checkmark-outline"></nb-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="ref.close()">Cancel</button>
      <button
        nbButton
        class="float-right"
        status="primary"
        [disabled]="!uploadFiles.length || isUploading"
        (click)="submitUpload(ref)"
        [nbSpinner]="isUploading"
        nbSpinnerStatus="warning"
      >
        Upload {{ uploadFiles.length }} files
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #zoomDialog let-data let-ref="zoomRef">
  <nb-card>
    <nb-card-body>
      <next-attachment-image
        [width]="innerWidth - 800"
        [attachment]="data"
        [autoPlay]="true"
      ></next-attachment-image>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #dialogDeleteAttachment let-data let-ref="dialogRef">
  <nb-card accent="danger">
    <nb-card-header
      class="dialog-header"
      style="display: flex; justify-content: center"
      >Are you sure?
    </nb-card-header>
    <nb-card-body>
      <div style="display: flex; justify-content: center">
        <nb-icon
          status="danger"
          style="font-size: 4rem"
          class="delete-icon"
          icon="alert-circle-outline"
        ></nb-icon>
      </div>
      <p>This will delete the attachment</p>
    </nb-card-body>
    <nb-card-footer style="display: flex">
      <button nbButton (click)="ref.close()">Cancel</button>
      <span class="flex-expand"></span>
      <button nbButton status="danger" (click)="removeAttachment(data, ref)">
        Delete
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
