import { gql } from 'apollo-angular';

export const Query_GetWorkOrderSegmentLines = gql`
  query ($search: DtoPaginatedFilter!) {
    getWorkOrderSegmentLines(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          itemId
          qty
          description
          status
          type
          qtyToUse
          qtyUsed
          lineNum
          pickUpRequestLines {
            id
            pickUpRequest {
              id
              scheduledPickUpDateTime
              status
            }
          }
        }
      }
    }
  }
`;
