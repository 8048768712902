import { gql } from 'apollo-angular';

export const Mutation_SaveChecklist = gql`
  mutation saveChecklist($dto: DtoUpdateChecklist!) {
    saveChecklist(dto: $dto) {
      id
      updatedAt
      status
      name
      json
      description
      equipmentMake
      equipmentModel
      equipmentSerial
      summaryText
      category
      group
    }
  }
`;
